export const enum SourceFileType {
  Pdf = 'pdf',
  Url = 'url',
  Text = 'txt',

  // Do not use them for CUD.
  ScrapedUrl = 'scu', // Backend system type. Do not use for CUD
  ScrapedPdf = 'scp', // Backend system type. Do not use for CUD
}

export const enum FileType {
  Pdf = 'application/pdf',
  Text = 'text/plain',
}
