import { useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters } from 'react-query';
import { ProjectModel } from 'containers/project-list-page/lib/models/Project.model';
import { useGetProjectBy } from 'containers/project-list-page/lib/query-hooks';

type Props = {
  projectId: number;
  data: ProjectModel;
  isLoading: boolean;
  // eslint-disable-next-line max-len
  refetchProject: <TPageData>(options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined) => Promise<QueryObserverResult<ProjectModel, Error>>;
};
function useGetProjectById(): Props {
  const location = useLocation();

  const idRef = useRef(0);

  location.pathname.split('/')
    .forEach((text) => {
      const num = Number(text);

      if (num > 0) {
        idRef.current = num;
      }
    });

  const { data, isLoading, refetch } = useGetProjectBy(idRef.current.toString());

  return {
    projectId: idRef.current,
    data: data || {} as ProjectModel,
    isLoading,
    refetchProject: refetch,
  };
}

export default useGetProjectById;
