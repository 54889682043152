import sc from '@mui/styled-engine';

import { TabPanel } from '@mui/lab';
import {
  Button, Grid, Paper, Stack,
} from '@mui/material';

const EDIT_BUTTON_BLOCK_HEIGHT = 30 as const;
const HEADER_HEIGHT = 60 as const;

export const InformationTabPanelWrapper = sc(Grid)({
  overflow: 'auto',
  height: `calc(100% - ${EDIT_BUTTON_BLOCK_HEIGHT}px)`,
  flexDirection: 'column',
});

export const InvitedPeopleWrapperStyled = sc(Stack)({
  height: '100%',
  overflow: 'auto',
});

export const InvitedPeopleContainerStyled = sc(Grid)(({ theme }) => ({
  flexDirection: 'column',
  flexWrap: 'nowrap',
  overflow: 'auto',
  height: '326px',
  paddingBottom: '44px',
  marginTop: theme.spacing(10),
}));

export const ButtonStyled = sc(Button)(({ theme }) => ({
  padding: theme.spacing(1.5, 5),
  height: '36px',
  flexShrink: 0,
}));

export const PaperStyled = sc(Paper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row-reverse',
  alignItems: 'center',
  padding: theme.spacing(4, 8),
}));

export const TextManagerTabPanelStyled = sc(TabPanel)({
  overflow: 'auto',
  height: `calc(100% - ${HEADER_HEIGHT}px)`,
});
