import { TabPanel } from '@mui/lab';
import { Grid, Typography } from '@mui/material';

import InvitedPeopleItem from 'common/components/invited-people-item';
import useGetProjectById from 'common/query-hooks/use-get-project-by-id';
import { TabRoute } from 'common/components/routes/utils/constants';
import withAlternativePhrase from 'common/HOCs/with-alternative-phrase';
import { useGetLanguageList } from 'common/components/create-edit-page/lib/query-hooks';

import { NO_DATA } from 'common/HOCs/constants';
import { InputLabelText } from '../utils/constants';

import {
  InformationTabPanelWrapper,
  InvitedPeopleContainerStyled, InvitedPeopleWrapperStyled,
} from '../utils/styled';

function InformationTab(): JSX.Element {
  const { data } = useGetProjectById();
  const { data: languages } = useGetLanguageList();

  const projectLanguage = languages
    ?.find((language) => language.code === data.projectLanguage)
    ?.slug;

  return (
    <TabPanel value={TabRoute.Description}>
      <InformationTabPanelWrapper container wrap="nowrap">
        <Grid container wrap="nowrap">
          <Grid item xs={6}>
            <Typography variant="body1">{InputLabelText.Name}</Typography>
            <Typography variant="body1" mt={3}>
              {withAlternativePhrase<string>(data?.projectName)}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">{InputLabelText.Language}</Typography>
            <Typography variant="body1" mt={3}>
              {projectLanguage}
            </Typography>
          </Grid>
        </Grid>

        <Grid item mt={8}>
          <Typography variant="body1">{InputLabelText.Description}</Typography>
          <Typography variant="body1" mt={3}>
            {withAlternativePhrase<string>(data?.description)}
          </Typography>
        </Grid>

        <Grid container mt={8}>
          <Grid item xs={6}>
            <Typography variant="body1">{InputLabelText.StartDate}</Typography>
            <Typography variant="body1" mt={3}>
              {withAlternativePhrase<string>(data?.startDate)}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">{InputLabelText.EndDate}</Typography>
            <Typography variant="body1" mt={3}>
              {withAlternativePhrase<string>(data?.endDate)}
            </Typography>
          </Grid>
        </Grid>

        <InvitedPeopleContainerStyled container>
          <Typography variant="body1">{InputLabelText.Shared}</Typography>
          {(data?.canSee?.length)
            ? (
              <InvitedPeopleWrapperStyled>
                { data?.canSee.map((person) => (
                  <InvitedPeopleItem
                    key={person.email}
                    person={person}
                  />
                ))}
              </InvitedPeopleWrapperStyled>
            )
            : <Typography variant="body1"><em>{NO_DATA}</em></Typography>}

        </InvitedPeopleContainerStyled>
      </InformationTabPanelWrapper>
    </TabPanel>
  );
}

export default InformationTab;
