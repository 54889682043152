import { AxiosError, AxiosResponse, AxiosResponseHeaders } from 'axios';

import axiosInstance from 'common/interceptors/axios';
import { storage } from 'common/storage';
import { Token } from 'common/storage/types';
import { Credentials } from 'common/utils/models/login';
import { User } from 'common/utils/models/user';
import jwtDecode from 'jwt-decode';

import { Endpoint } from './utils/constants';

const axios = axiosInstance;

export const login = async (credentials: Credentials):
  Promise<string> => new Promise<string>(
    (resolve, reject) => {
      axios.post<AxiosResponseHeaders>(Endpoint.Login, {
        username: credentials.username,
        password: credentials.password,
      })
        .then((response: AxiosResponse<AxiosResponseHeaders>) => resolve(response.headers.authorization))
        .catch((error: AxiosError<string>) => reject(error));
    },
  );

export const logout = async ():
  Promise<Token> => new Promise<Token>(
    (resolve, reject) => {
      const refreshToken = storage.getRefreshToken();
      axios.post<Token>(Endpoint.Logout, { refreshToken: refreshToken as string })
        .then((response: AxiosResponse<Token>) => {
          if (response?.status === 201) {
            storage.clearAll();
          }
          return resolve(response.data);
        })
        .catch((error: AxiosError<string>) => reject(error));
    },
  );

export const getUser = async (): Promise<User> => new Promise<User>(
  (resolve, reject) => {
    axios.get<User>(Endpoint.Me)
      .then((response: AxiosResponse<User>) => resolve(response.data))
      .catch((error: AxiosError<string>) => reject(error));
  },
);

export const relogin = async ():
  Promise<Token> => new Promise<Token>(
    (resolve, reject) => {
      const refreshToken = storage.getRefreshToken();
      axios.post<Token>(Endpoint.Refresh, { refreshToken: refreshToken as string })
        .then(async (response: AxiosResponse<Token>) => {
          if (response?.status === 201) {
            storage.clearAll();
            const decodedToken = jwtDecode(response.headers.authorization) as Token;
            storage.setToken(decodedToken);
          }
          return resolve(response.data);
        })
        .catch(async (error: AxiosError<string>) => {
          storage.clearAll();
          return reject(error);
        });
    },
  );
