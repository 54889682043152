import React, { forwardRef, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useSnackbar, SnackbarContent } from 'notistack';

import CloseIcon from '@mui/icons-material/Close';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import {
  Box, Card, CardActions,
  IconButton, Typography,
} from '@mui/material';
import { SnackContent } from './types';

const SnackMessage = forwardRef<HTMLDivElement,
 { id: string | number, message: Partial<SnackContent> }>(
   ({
     id, message: {
       id: projectId, projectName, message, actionType,
     },
   }, ref) => {
     const { closeSnackbar } = useSnackbar();

     const handleDismiss = useCallback(() => {
       closeSnackbar(id);
     }, [id, closeSnackbar]);

     return (
       <SnackbarContent ref={ref}>
         <Card
           sx={{
             width: 350,
             minHeight: 144,
             mt: 9,
           }}
           elevation={3}
         >
           <CardActions sx={{ width: '100%', justifyContent: 'space-between' }}>
             <Typography variant="subtitle1" pl={3}>{projectName}</Typography>
             <IconButton onClick={handleDismiss} size="medium">
               <CloseIcon fontSize="medium" />
             </IconButton>
           </CardActions>
           <Box pl={4} pr={4}>
             <Typography variant="body1">{message}</Typography>
           </Box>
           {
             actionType !== 'delete' && (
               <Box pl={4} pr={4} pt={6} height={40}>
                 <Link to={`/projects/${projectId}`}>
                   <Typography
                     variant="body1"
                     color="info.main"
                     fontWeight="bold"
                     component="div"
                     sx={{ display: 'flex' }}
                   >
                     {`Go to project ${projectId}`}
                     <ArrowForwardIcon fontSize="small" sx={{ ml: 3 }} />
                   </Typography>
                 </Link>
               </Box>
             )
           }
         </Card>
       </SnackbarContent>
     );
   },
 );

export default SnackMessage;
