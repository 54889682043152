import { Fragment, useState } from 'react';

import { Grid, Typography } from '@mui/material';

import { ButtonText } from 'containers/project-manager-page/utils/constants';
import {
  useModalContext,
  useSetTabOrientationContext,
} from 'containers/project-manager-page/local-state/hooks';
import
ReactHookFormTextFieldOutlined
  from 'common/components/react-hook-forms/ReactHookFormTextFieldOutlined';
import { SourceFileType } from 'common/utils/models/file-type';

import { InputPlaceholderText, TextManagerText } from '../../utils/constants';
import { InputFieldName } from './utils/constants';
import { useUploadUrl } from './utils/useUploadUrl';
import AccordionItem from '../../additional-components/accordion/AccordionItem';
import AccordionNested from '../../additional-components/accordion/AccordionNested';
import FilesModal from './FilesModal';

import { ButtonStyled } from '../../utils/styled';
import { InputsWrapperStyled, UrlsPaperStyled } from './utils/styled';

function URLs(): JSX.Element {
  const { state: { tabOrientedTo } } = useSetTabOrientationContext();
  const { state: { id } } = useModalContext();

  const {
    control,
    dataSources,
    onSend,
    handleSubmit,
  } = useUploadUrl();

  const [expanded, setExpanded] = useState<string | false>(false);

  const expandHandle = (panel: string) => (_: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      <Typography variant="subtitle1">{TextManagerText.URLs}</Typography>
      <UrlsPaperStyled
        component="form"
        onSubmit={handleSubmit(onSend)}
        elevation={3}
      >
        {tabOrientedTo === 'project-manager' && (
          <InputsWrapperStyled item container flexWrap="nowrap">
            <Grid item xs={9} pr={8}>
              <ReactHookFormTextFieldOutlined
                type="url"
                fullWidth
                label={InputPlaceholderText.URL}
                name={InputFieldName.SourceLocation}
                control={control}
              />
            </Grid>
            <Grid item xs={3} pr={8}>
              <ReactHookFormTextFieldOutlined
                placeholder={InputPlaceholderText.URLName}
                name={InputFieldName.SourceName}
                label={InputPlaceholderText.URLName}
                control={control}
                type="text"
                fullWidth
              />
            </Grid>
            <ButtonStyled type="submit" variant="outlined">
              {ButtonText.Add}
            </ButtonStyled>
          </InputsWrapperStyled>
        )}
        {!!dataSources?.length && dataSources.map((source) => (
          (source.type === SourceFileType.Url) && (
            <Fragment key={source.id}>
              <AccordionItem
                isParentUrlAccordion
                source={source}
                expanded={expanded === source.id.toString()}
                expandHandle={expandHandle(`${source.id}`)}
              />
              {!!dataSources?.length && dataSources
                .map((childSource) => (!!source.children.length && childSource.id === source.id) && (
                  <AccordionNested
                    key={childSource.id}
                    sources={childSource.children}
                  />
                ))}
            </Fragment>
          )
        ))}
        {id === 'files' && <FilesModal />}
      </UrlsPaperStyled>
    </>
  );
}

export default URLs;
