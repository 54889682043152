import sc from '@mui/styled-engine';

import { TabPanel } from '@mui/lab';
import {
  Container, Grid, Paper, Typography,
} from '@mui/material';

const HEADER_HEIGHT = 60 as const;

export const ResultsTabPanelStyled = sc(TabPanel)({
  overflow: 'auto',
  height: `calc(100% - ${HEADER_HEIGHT}px)`,
});

export const ColoredSquareStyled = sc(Container)(({ theme }) => ({
  height: 24,
  width: 24,
  alignSelf: 'start',
  margin: 0,
  marginRight: theme.spacing(3),
  borderRadius: theme.shape.borderRadius / 2,

}));

export const ResultsTabWrapperStyled = sc(Grid)({
  flexDirection: 'column',
  height: '100%',
  display: 'flex',
});

export const ExtractedTermsStyled = sc(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: 'calc(100% - 224px)', // TODO: Fix
  flex: 1,
  position: 'fixed',
  padding: theme.spacing(0, 2),
  maxWidth: 'min(353px, calc(30% - 10px))',
}));

export const NumberOfTermsWrapper = sc(Grid)(({ theme }) => ({
  paddingTop: theme.spacing(5),
  alignItems: 'center',
  display: 'flex',
}));

export const CopyToClipboardWrapperStyled = sc(Grid)(({ theme }) => ({
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(3, 0),
}));

export const PaperStyled = sc(Paper)<{ $isFetching?: boolean; }>(({ theme, $isFetching }) => ({
  overflow: 'auto',
  padding: theme.spacing(4),
  border: `2px solid ${theme.palette.grey[300]}`,
  filter: $isFetching ? 'opacity(0.5)' : 'opacity(1)',
}));

export const TokenWordStyled = sc(Typography)<{ $backgroundColor?: string; component?: string; }>(({
  theme, $backgroundColor,
}) => ({
  display: 'table',
  margin: theme.spacing(2, 0),
  padding: theme.spacing(0, 1),
  backgroundColor: $backgroundColor,
  borderRadius: theme.shape.borderRadius / 2,
}));
