import { Status } from 'containers/project-list-page/utils/enums';
import { memo } from 'react';

import ProjectInfoAppBarSet from './ProjectInfoAppBarSet';

interface ProjectAppBarSetProps {
withStatus: boolean;
projectName?: string;
status?: `${Status}`
}

function ProjectAppBarSet({ withStatus, projectName, status }:ProjectAppBarSetProps): JSX.Element {
  return (
    <ProjectInfoAppBarSet
      withStatus={withStatus}
      headerText={withStatus ? (projectName ?? '') : 'some new project'}
      status={status || undefined}
    />
  );
}

export default memo(ProjectAppBarSet);
