import {
  Grid, Typography, OutlinedInput,
  Checkbox, FormControlLabel,
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DoneAllIcon from '@mui/icons-material/DoneAll';

import { ControlLabel, ExtractedTermsText } from './utils/constants';
import { useSetExtractedTerms } from './utils/useSetExtractedTerms';
import { useCurrentProjectLanguage } from '../text-manager-tab/utils/useCurrentProjectLanguage';

import {
  CopyToClipboardWrapperStyled, ExtractedTermsStyled,
  NumberOfTermsWrapper, PaperStyled, TokenWordStyled,
} from './utils/styled';

function ExtractedTerms(): JSX.Element {
  const { language } = useCurrentProjectLanguage();

  const isNamedEntityEnabled = language?.supportNer;
  const isAbbreviationEnabled = language?.supportAbbr;
  const isSpecificTermsEnabled = language?.supportSt;

  const {
    report,
    tokens,
    lastExtraction,
    isFetching,
    isCopiedToClipboard,
    changeNumberOfTermsHandle,
    copyToClipboardHandle,
    handleChangeCheckbox,
    setBackground,
  } = useSetExtractedTerms();

  return (
    <ExtractedTermsStyled item>
      <Typography variant="body2" sx={{ pb: 4, pt: 4 }}>
        {lastExtraction}
      </Typography>
      <Typography variant="body1" fontWeight="bold">
        {ExtractedTermsText.Terms}
      </Typography>
      <NumberOfTermsWrapper item container wrap="nowrap">
        <Typography variant="body1" sx={{ pr: 4 }}>
          {ExtractedTermsText.NumberOfTerms}
        </Typography>
        <OutlinedInput
          type="number"
          onChange={changeNumberOfTermsHandle}
          defaultValue={report.limit}
          error={report.limit < 1}
        />
      </NumberOfTermsWrapper>
      <Grid
        item
        container
        wrap="nowrap"
        sx={{ pt: 5 }}
      >
        {isNamedEntityEnabled && (
          <FormControlLabel
            label={ControlLabel.Entity}
            control={(
              <Checkbox
                checked={report.types.includes('ner')}
                name="ner"
                onChange={handleChangeCheckbox}
              />
            )}
          />
        )}
        {isAbbreviationEnabled && (
          <FormControlLabel
            label={ControlLabel.Abbr}
            control={(
              <Checkbox
                checked={report.types.includes('abbr')}
                name="abbr"
                onChange={handleChangeCheckbox}
              />
            )}
          />
        )}
        {isSpecificTermsEnabled && (
          <FormControlLabel
            label={ControlLabel.SpecificTerm}
            control={(
              <Checkbox
                checked={report.types.includes('st')}
                name="st"
                onChange={handleChangeCheckbox}
              />
            )}
          />
        )}
      </Grid>
      <CopyToClipboardWrapperStyled
        item
        container
        onClick={copyToClipboardHandle}
      >
        <Typography
          variant="body2"
          fontStyle="italic"
        >
          {ExtractedTermsText.Mark}
        </Typography>
        {isCopiedToClipboard
          ? (
            <Grid display="inline-flex">
              <DoneAllIcon
                fontSize="small"
                color="success"
                sx={{ mr: 2, cursor: 'pointer' }}
              />
              <Typography
                variant="body2"
                color="green"
                sx={{ cursor: 'pointer' }}
              >
                {ExtractedTermsText.Copied}
              </Typography>
            </Grid>
          )
          : (
            <Grid display="inline-flex">
              <ContentCopyIcon
                fontSize="small"
                color="primary"
                sx={{ mr: 2, cursor: 'pointer' }}
              />
              <Typography
                variant="body2"
                color="primary"
                sx={{ cursor: 'pointer' }}
              >
                {ExtractedTermsText.CopyToClipboard}
              </Typography>
            </Grid>
          )}
      </CopyToClipboardWrapperStyled>
      <PaperStyled elevation={0} $isFetching={isFetching}>
        {
          tokens?.map((item, index) => (
            <TokenWordStyled
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              $backgroundColor={setBackground(item.type)}
            >
              {item?.token}
            </TokenWordStyled>
          ))
        }
      </PaperStyled>
    </ExtractedTermsStyled>
  );
}

export default ExtractedTerms;
