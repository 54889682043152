import { useState } from 'react';

import { UseFormReturn } from 'react-hook-form';

import {
  Grid, MenuItem, SelectChangeEvent, Typography,
} from '@mui/material';

import ReactHookFormSelect from 'common/components/react-hook-forms/ReactHookFormSelect';

import { LanguageModel } from '../lib/models/language.model';
import { useGetLanguageList } from '../lib/query-hooks';

import { InputText } from '../utils/constants';
import { getKeyOf, ProjectFieldValues } from './utils/types';

interface LanguageSelectProps {
  formProps: UseFormReturn<ProjectFieldValues, object>,
}

function LanguageSelect({ formProps }: LanguageSelectProps): JSX.Element {
  const { data: languages } = useGetLanguageList();
  const [error, setError] = useState<boolean>(true);

  const { onChange, ...rest } = formProps.register('projectLanguage');

  const onChangeHandle = (event: SelectChangeEvent<unknown>): void => {
    setError(false);
    onChange(event);
  };

  return (
    <Grid item xs={3}>
      <Typography pb={2} variant="subtitle1" noWrap>
        {InputText.ProjectLanguage}
      </Typography>
      <ReactHookFormSelect
        {...rest}
        name={getKeyOf<ProjectFieldValues>('projectLanguage')}
        onChange={onChangeHandle}
        control={formProps?.control}
        error={error}
        displayEmpty
        renderValue={(selected) => {
          if (typeof selected === 'string') {
            if (selected?.length === 0) return <em>Select language</em>;
            if (selected === 'Select language') return null;
            const selectedLanguage = languages?.find((langage) => langage.code === selected)?.slug;
            setError(!selected || selected === 'Select language');

            return selectedLanguage || selected;
          }

          return null;
        }}
      >
        {
          !!languages?.length
          && languages?.map(({ code, slug }: LanguageModel) => (
            <MenuItem key={code} value={slug}>{slug}</MenuItem>
          ))
        }
      </ReactHookFormSelect>
    </Grid>
  );
}

export default LanguageSelect;
