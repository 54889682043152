import { Fragment, useMemo, useState } from 'react';

import Checkbox from '@mui/material/Checkbox';
import { Divider, Typography } from '@mui/material';

import { useSetTooMuchFilesContext } from 'containers/project-manager-page/local-state/hooks';
import CheckedItem from './CheckedItem';
import { MODAL_COL_NAME } from './utils/constants';
import { CheckedDataSource } from '../../../../local-state/TooMuchFilesContext';

import { ListStyled, ListSubheaderStyled } from './utils/styled';

function CardContentList(): JSX.Element {
  const { dispatch: tooMuchFilesDispatch, state: { files } } = useSetTooMuchFilesContext();

  const [checked, setChecked] = useState<CheckedDataSource[]>(files);

  const handleCheckAll = (_: React.ChangeEvent<HTMLInputElement>, isChecked: boolean): void => {
    const newChecked = [...checked];
    const checkedParent = newChecked[0].parent as number;
    const checkedAllItems = newChecked.map((c) => ({ ...c, checked: isChecked }));
    setChecked(checkedAllItems);
    tooMuchFilesDispatch({ setFiles: checkedAllItems, setParents: [checkedParent] });
  };

  const handleToggle = (value: CheckedDataSource) => () => {
    const newChecked = [...checked];
    const index = newChecked.findIndex((c) => c.id === value.id);
    newChecked[index].checked = !value.checked;

    setChecked(newChecked);
  };

  const allChecked = useMemo(() => checked.every((c) => c.checked), [checked]);
  const indeterminateChecked = useMemo(
    () => checked.some((c) => c.checked) && !allChecked,
    [allChecked, checked],
  );

  return (
    <ListStyled
      subheader={(
        <>
          <ListSubheaderStyled>
            <Checkbox
              edge="start"
              checked={allChecked}
              tabIndex={-1}
              disableRipple
              indeterminate={indeterminateChecked}
              onChange={handleCheckAll}
              sx={{ width: '7%', justifyContent: 'flex-start' }}
            />
            <Typography
              variant="body2"
              fontWeight="bold"
            >
              {MODAL_COL_NAME}
            </Typography>
          </ListSubheaderStyled>
          <Divider variant="fullWidth" component="li" />
        </>
      )}
    >
      {checked.map((value) => (
        <Fragment key={value.id}>
          <CheckedItem toggle={handleToggle} value={value} />
          {files[files.length - 1] !== value && <Divider variant="fullWidth" component="li" />}
        </Fragment>
      ))}
    </ListStyled>
  );
}

export default CardContentList;
