import { AxiosError, AxiosResponse } from 'axios';
import axiosInstance from 'common/interceptors/axios';

import Endpoint from './Endpoint';
import { ProjectListResult, ProjectModel } from './models/Project.model';

const axios = axiosInstance;

export const getProjectList = async (
  pageParam: number,
): Promise<ProjectListResult> => new Promise<ProjectListResult>(
  (resolve, reject) => {
    axios.get<ProjectListResult>(
      `${Endpoint.GetProjectList}?limit=50&offset=${pageParam}&ordering=-updateDate`,
    )
      .then((response: AxiosResponse<ProjectListResult>) => resolve(response.data))
      .catch((error: AxiosError<string>) => reject(error));
  },
);

export const getProjectBy = async (id: string): Promise<ProjectModel> => new Promise<ProjectModel>(
  (resolve, reject) => {
    axios.get<ProjectModel>(`${Endpoint.GetProjectById}/${id}`)
      .then((response: AxiosResponse<ProjectModel>) => resolve(response.data))
      .catch((error: AxiosError<string>) => reject(error));
  },
);

export const deleteProjectBy = async (id: string): Promise<ProjectModel> => new Promise<ProjectModel>(
  (resolve, reject) => {
    axios.delete<ProjectModel>(`${Endpoint.DeleteProjectById}/${id}`)
      .then((response: AxiosResponse<ProjectModel>) => resolve(response.data))
      .catch((error: AxiosError<string>) => reject(error));
  },
);
