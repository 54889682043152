import { CreateEditProjectAction, CreateEditProjectState } from './CreateEditTypeContext';
import { RemoveOptionAction, RemoveOptionState } from './RemoveOptionContext';
import { UsersAction, UsersState } from './UsersContext';

function createEditReducer(
  state: CreateEditProjectState,
  action: CreateEditProjectAction,
): CreateEditProjectState {
  const copy = { ...state };
  if (action.type === 'drop') {
    copy.value = null;
  } else copy.value = action.type;

  return copy;
}

function removeOptionReducer(
  state: RemoveOptionState,
  action: RemoveOptionAction,
): RemoveOptionState {
  const copy = { ...state };
  if (action.type === 'remove' && action.username) {
    copy.value = action.username;
  }

  return copy;
}

function usersReducer(
  state: UsersState,
  action: UsersAction,
): UsersState {
  if (action.type === 'set-selected-users') {
    return { ...state, selectedUsers: action.users };
  }
  return { ...state, defaultUsers: action.users };
}

export {
  createEditReducer,
  removeOptionReducer,
  usersReducer,
};
