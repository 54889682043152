import { FC, useMemo, useReducer } from 'react';

import { sendEmailReducer } from './reducers';
import { SendEmailContext } from './SendEmailContext';

const SendEmailContextProvider: FC = function ({ children }): JSX.Element {
  const [sendEmailState, sendEmailDispatch] = useReducer(sendEmailReducer, { value: [] });

  const sendEmailContextValue = useMemo(() => ({
    state: sendEmailState,
    dispatch: sendEmailDispatch,
  }), [sendEmailState]);

  return (
    <SendEmailContext.Provider value={sendEmailContextValue}>
      {children}
    </SendEmailContext.Provider>
  );
};

export default SendEmailContextProvider;
