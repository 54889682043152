import { defaultTheme } from '../defaultTheme';

const MuiSelect = {
  styleOverrides: {
    select: {
      padding: defaultTheme.spacing(1.5, 3),
    },
  },
};
export default MuiSelect;
