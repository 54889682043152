import sc from '@mui/styled-engine';

import Tab from '@mui/material/Tab';
import Grid from '@mui/material/Grid';
import { Button } from '@mui/material';

import CircularSpinner from 'common/components/circular-spinner/circular-spinner';
import { AnyComponentType } from 'containers/project-list-page/utils/types';

const HEADER_HEIGHT = 60 as const;

export const ProjectManagerContainerStyled = sc(Grid)(({ theme }) => ({
  justifyContent: 'center',
  marginTop: theme.spacing(4),
  height: '100%',
  paddingBottom: theme.spacing(3.5),
}));

export const TabsContainerStyled = sc(Grid)({
  alignItems: 'flex-start',
  justifyContent: 'center',
  height: '100%',
  overflow: 'hidden',
});

export const TabStyled = sc(Tab)({
  fontWeight: 'bold',
  textDecoration: 'auto !important',
  textTransform: 'capitalize',
}) as AnyComponentType;

type TabPanelsWrapperExtended = {
  $isOverflowHidden?: boolean;
};
export const TabPanelsWrapper = sc(Grid)<TabPanelsWrapperExtended>(({ $isOverflowHidden }) => ({
  overflow: $isOverflowHidden ? 'hidden' : 'auto',
  height: `calc(100% - ${HEADER_HEIGHT}px)`,
}));

export const EditButtonStyled = sc(Button)(({ theme }) => ({
  padding: theme.spacing(1),
}));

export const CircularSpinnerStyled = sc(CircularSpinner)(({ theme }) => ({
  marginLeft: theme.spacing(2),
}));
