import useGetProjectById from 'common/query-hooks/use-get-project-by-id';
import { DataSource } from 'common/utils/models/data-source';
import { useEffect } from 'react';
import { useAddSource } from '../../lib/query-hooks';

interface SendFile {
  dataSources: DataSource[] | undefined;
  isAdding: boolean;
  projectId: number;
}

const useSendFile = ({ acceptedFiles }: { acceptedFiles: File[]; }): SendFile => {
  const { data, projectId } = useGetProjectById();
  const dataSources = data?.dataSources;

  const { mutateAsync: addAsync, isLoading: isAdding } = useAddSource();

  useEffect(() => {
    if (projectId && acceptedFiles.length === 1) {
      addAsync({
        id: projectId,
        sourceName: acceptedFiles[0].name,
        type: 'pdf',
        file: acceptedFiles[0],
      });
    }
  }, [acceptedFiles, addAsync, projectId]);

  return {
    dataSources,
    isAdding,
    projectId,
  };
};

export default useSendFile;
