import { AxiosError, AxiosResponse } from 'axios';

import axiosInstance from 'common/interceptors/axios';
import { User } from 'common/utils/models/user';

import { Endpoint } from './Endpoint';
import { LanguageModel } from './models/language.model';

const axios = axiosInstance;

export const getLanguageList = async (): Promise<LanguageModel[]> => new Promise<LanguageModel[]>(
  (resolve, reject) => {
    axios.get<LanguageModel[]>(Endpoint.Languages)
      .then((response: AxiosResponse<LanguageModel[]>) => resolve(response.data))
      .catch((error: AxiosError<string>) => reject(error));
  },
);

export const getMyKeyCloakUserList = async (
  { myUserName }: { myUserName: string; },
): Promise<User[]> => new Promise<User[]>(
  (resolve, reject) => {
    axios.get<User[]>(Endpoint.MyKeyCloakUsers, { data: { username: myUserName } })
      .then((response: AxiosResponse<User[]>) => resolve(response.data))
      .catch((error: AxiosError<string>) => reject(error));
  },
);
