import { memo, useState } from 'react';

import {
  Checkbox, Grid, IconButton, Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { User } from 'common/utils/models/user';

import { useSendEmailContext } from '../create-edit-page/local-state/hooks';

import { AvatarStyled, IvitedPeopleItemStyled, IvitedPeopleManipulationStyled } from './styled';

interface IvitedPeopleItem {
  person: Partial<User>;
  removeOption?: VoidFunction;
  isInformationPage?: boolean;
}

function InvitedPeopleItem({
  person, isInformationPage, removeOption,
}: IvitedPeopleItem): JSX.Element {
  const { dispatch } = useSendEmailContext();

  const [checked, setChecked] = useState(person.sendInvite);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setChecked(event.target.checked);
    if (person?.email) {
      dispatch({
        field: {
          type: event.target.checked ? 'add' : 'remove',
          entity: {
            ...person,
            sendInvite: event.target.checked,
          } as Required<User>,
        },
      });
    }
  };

  return (
    <IvitedPeopleItemStyled
      item
      container
      columnGap={4}
      wrap="nowrap"
    >
      <AvatarStyled alt={person.email} src="" />
      <Grid item xs={9}>
        <Typography variant="subtitle1">
          {person?.username}
        </Typography>
        <Typography variant="body1" fontStyle="italic">
          {person?.email}
        </Typography>
      </Grid>
      {isInformationPage && (
        <IvitedPeopleManipulationStyled item xs={3}>
          <Checkbox
            checked={checked}
            onChange={handleChange}
            inputProps={{ 'aria-label': 'controlled' }}
          />
          <IconButton onClick={removeOption}>
            <CloseIcon />
          </IconButton>
        </IvitedPeopleManipulationStyled>
      )}
    </IvitedPeopleItemStyled>
  );
}

export default memo(InvitedPeopleItem);
