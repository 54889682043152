import sc from '@mui/styled-engine';

import { Box } from '@mui/material';

export const AppContainerStyled = sc(Box)({
  display: 'flex',
  flex: 1,
  overflow: 'hidden',
  margin: 'auto',
  height: '100%',
  alignItems: 'flex-start',
  justifyContent: 'center',
  width: '100%',
});
