import { memo } from 'react';

import { DataSource } from 'common/utils/models/data-source';
import { useSetTabOrientationContext } from 'containers/project-manager-page/local-state/hooks';

import useGetDataSource from '../../text-manager-tab/files/utils/useGetContent';
import useUpadateDebouncedText from '../../text-manager-tab/files/utils/useUpadateDebouncedText';
import useSetHighlight from './utils/useSetHighLightResult';

import { AccordionDetailsStyled, SourceTextStyled } from './styled';

interface AccordionDetailsComponentProps {
  expanded: boolean;
  source: DataSource;
  isNested?: boolean;
}

function AccordionDetailsComponent({
  source,
  expanded,
  isNested,
}: AccordionDetailsComponentProps): JSX.Element {
  const { state: { tabOrientedTo } } = useSetTabOrientationContext();
  const { textChangeHandle, textSetHandle } = useUpadateDebouncedText({ source });
  const {
    textContent,
    tokens,
  } = useGetDataSource({ source, expanded });
  const { result } = useSetHighlight({ tokens, textContent });

  return (
    <AccordionDetailsStyled>
      <SourceTextStyled
        component="div"
        contentEditable={tabOrientedTo !== 'results'}
        suppressContentEditableWarning
        onFocus={textSetHandle}
        onInput={textChangeHandle}
        sx={{ ml: isNested ? 4 : 0 }}
      >
        {
          tabOrientedTo === 'project-manager'
            ? textContent
            : result.texts.map(({ color, text }, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <span key={index} style={{ backgroundColor: color }}>{text}</span>
            ))
        }
      </SourceTextStyled>
    </AccordionDetailsStyled>
  );
}
export default memo(AccordionDetailsComponent);
