import { User } from 'common/utils/models/user';
import React from 'react';

type SendEmailEntity = User;

type SendEmail = {
  type: 'add' | 'remove' | 'drop-results';
  entity?: SendEmailEntity;
};

export type SendEmailAction = {
  field: SendEmail,
};
export type SendEmailState = { value: SendEmailEntity[]; };
export type SendEmailDispatch = (action: SendEmailAction) => void;
export type SendEmailContextType = {
  state: SendEmailState;
  dispatch: SendEmailDispatch;
};

export const SendEmailContext = React
  .createContext<SendEmailContextType | undefined>(undefined);
