import memoizeOne from 'memoize-one';

import { TabRoute } from 'common/components/routes/utils/constants';

const tabRouteIsInURL = (elem: string): boolean => Object.values(TabRoute).some((value) => value === elem);

/**
 * If the route is on Edit mode function returns the pre-last element
 * otherwise returns last element from the splitted url.
 */
export const getTabValueFromRoute = memoizeOne((route: string): `${TabRoute}` => {
  const splitedRoute = route.split('/');
  const lastElement = splitedRoute[splitedRoute.length - 1] as string;

  if (lastElement === TabRoute.Edit) return splitedRoute[splitedRoute.length - 2] as `${TabRoute}`;
  if (!tabRouteIsInURL(lastElement)) return TabRoute.TextManager;

  return lastElement as `${TabRoute}`;
});
