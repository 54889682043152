import { useDropzone } from 'react-dropzone';

import { Typography } from '@mui/material';
import FileUploadIcon from '@mui/icons-material/FileUpload';

import { ButtonText } from 'containers/project-manager-page/utils/constants';
import CircularSpinner from 'common/components/circular-spinner/circular-spinner';

import { SourceFileType } from 'common/utils/models/file-type';
import { useState } from 'react';
import { useSetTabOrientationContext } from 'containers/project-manager-page/local-state/hooks';
import AccordionItem from '../../additional-components/accordion/AccordionItem';
import { dropzoneConfig } from '../../utils/configs';
import { TextManagerText } from '../../utils/constants';
import useSendFile from './utils/useSendFile';

import { ButtonStyled } from '../../utils/styled';
import { AccordionsPaperStyled, FilesContainerStyled } from './utils/styled';

function Files(): JSX.Element {
  const {
    open,
    acceptedFiles,
    getRootProps,
    getInputProps,
  } = useDropzone(dropzoneConfig);
  const { state: { tabOrientedTo } } = useSetTabOrientationContext();
  const { isAdding, dataSources } = useSendFile({ acceptedFiles });

  const [expanded, setExpanded] = useState<string | false>(false);

  const expandHandle = (panel: string) => (_: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      <Typography variant="subtitle1">{TextManagerText.Files}</Typography>
      <AccordionsPaperStyled elevation={3}>
        {tabOrientedTo === 'project-manager' && (
          <FilesContainerStyled {...getRootProps()}>
            <input {...getInputProps()} />
            <ButtonStyled
              variant="outlined"
              color="info"
              onClick={open}
            >
              <FileUploadIcon sx={{ mr: 1 }} />
              {ButtonText.FileUpload}
            </ButtonStyled>
            <Typography
              sx={{ width: '25%' }}
              variant="body2"
              pr={4}
            >
              {TextManagerText.FileUploadDescription}
            </Typography>
            {isAdding && (
              <CircularSpinner
                size="small"
                color="primary"
                style={{ width: '63%', display: 'block' }}
              />
            ) }
          </FilesContainerStyled>
        )}
        {!!dataSources?.length && dataSources.map((source) => (
          (source.type === SourceFileType.Pdf || source.type === SourceFileType.ScrapedPdf)
         && (
           <AccordionItem
             key={source.id}
             source={source}
             expanded={expanded === source.id.toString()}
             expandHandle={expandHandle(`${source.id}`)}
           />
         )
        ))}
      </AccordionsPaperStyled>
    </>
  );
}

export default Files;
