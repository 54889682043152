import { defaultTheme } from '../defaultTheme';

const MuiMenu = {
  styleOverrides: {
    list: {
      background: 'rgb(254 254 254)',
    },
    '&.MuiOutlinedInput-root': {
      height: 36,
      padding: defaultTheme.spacing(0, 3),
    },
  },
};
export default MuiMenu;
