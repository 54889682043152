import { green } from '@mui/material/colors';
import { defaultTheme } from '../defaultTheme';

const MuiChip = {
  styleOverrides: {
    colorPrimary: {
      backgroundColor: '#9E89D4',
    },
    colorSecondary: {
      backgroundColor: '#FFAA3B',
    },
    colorSuccess: {
      backgroundColor: '#74C276',
    },
    colorInfo: {
      backgroundColor: '#9E89D4',
    },
    colorError: {
      backgroundColor: '#E15959',
    },
    colorWarning: {
      backgroundColor: '#FFEF3B',
      color: 'black',
    },
    root: {
      '&.MuiChip-filledSuccess': {
        backgroundColor: green[500],
      },
      '&>span': {
        paddingTop: defaultTheme.spacing(0.5),
      },
    },
  },
};
export default MuiChip;
