import sc from '@mui/styled-engine';

import { Box } from '@mui/material';

import { ButtonStyled, PaperStyled } from 'containers/project-manager-page/components/utils/styled';

export const TextsPaperStyled = sc(PaperStyled)<{ component?: string; }>({
  flexDirection: 'column',
  display: 'block',
  padding: 0,
});

export const InputWrapperStyled = sc(Box)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(4, 8),
}));

export const ButtonTextsStyled = sc(ButtonStyled)(({ theme }) => ({
  marginLeft: 'auto',
  marginTop: theme.spacing(6),
  display: 'flex',
}));

export const ButtonContainerStyled = sc(Box)({
  display: 'flex',
  alignItems: 'baseline',
});
