import { DataSource } from 'common/utils/models/data-source';
import React from 'react';

export type CheckedDataSource = DataSource & { checked: boolean; };

export type TooMuchFilesAction = { setFiles: CheckedDataSource[]; setParents: number[]; };
export type TooMuchFilesState = { files: CheckedDataSource[]; parentIds: number[]; };
export type TooMuchFilesDispatch = (action: TooMuchFilesAction) => void;
export type TooMuchFilesContextType = {
  state: TooMuchFilesState;
  dispatch: TooMuchFilesDispatch;
};

export const TooMuchFilesContext = React.createContext<TooMuchFilesContextType | undefined>(undefined);
