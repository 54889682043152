import { useMemo } from 'react';

import { LanguageModel } from 'common/components/create-edit-page/lib/models/language.model';
import useGetProjectById from 'common/query-hooks/use-get-project-by-id';
import { useGetLanguageList } from 'common/components/create-edit-page/lib/query-hooks';

type Props = {
  language: LanguageModel | undefined;
  isLoading: boolean
};

export function useCurrentProjectLanguage(): Props {
  const { isLoading, data: project } = useGetProjectById();
  const { data: languages } = useGetLanguageList();

  const language = useMemo(
    () => languages?.find((lang) => lang.code === project?.projectLanguage),
    [languages, project?.projectLanguage],
  );

  return {
    isLoading,
    language,
  };
}
