const MuiSlider = {
  styleOverrides: {
    root: {
      marginBottom: 0,
    },
    markLabel: {
      fontSize: 12,
      transform: 'translateX(-100%)',
      '&[data-index="0"]': {
        transform: 'translateY(-300%)',
      },
      '&[data-index="1"]': {
        transform: 'translate(-50%, -300%)',
      },
      '&[data-index="2"]': {
        transform: 'translate(-100%, -300%)',
      },
    },
  },
};
export default MuiSlider;
