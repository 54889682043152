import {
  useMutation, UseMutationResult, useQuery, useQueryClient, UseQueryResult,
} from 'react-query';

import SnackBarUtils from 'common/SnackBar/SnackBarUtils';
import { ProjectModel } from 'containers/project-list-page/lib/models/Project.model';
import useGetProjectById from 'common/query-hooks/use-get-project-by-id';
import { relogin } from 'containers/authentication/lib/api';
import * as api from './api';
import QueryKey from './QueryKey';
import {
  DeleteMutationResult, Detail, ExtractionModel, MutationResult,
} from './types';
import { CreateDataSourceModel } from './models/CreateDataSource.model';

export function useAddSource(): MutationResult<Partial<CreateDataSourceModel>> {
  const queryClient = useQueryClient();

  return useMutation(
    (dataSource: Partial<CreateDataSourceModel>) => api.createDataSource(
      dataSource as Required<CreateDataSourceModel>,
    ),
    {
      onSettled: async (data) => {
        await queryClient.invalidateQueries([QueryKey.Project, data?.project.toString()]);
      },
      onError: async (error: Error, project: Partial<CreateDataSourceModel>, context: unknown) => {
        const rollback = context;
        if (typeof rollback === 'function') {
          rollback();
          SnackBarUtils.toast({ id: project.id?.toString(), message: error.message });
        }

        await queryClient.invalidateQueries([QueryKey.Project, project.id?.toString()]);
      },
    },
  );
}

export function useAddUrlsSource(): MutationResult<Partial<CreateDataSourceModel>> {
  const queryClient = useQueryClient();

  return useMutation(
    (dataSource: Partial<CreateDataSourceModel>) => api.createUrlsDataSource(
      dataSource as Required<CreateDataSourceModel>,
    ),
    {
      onSettled: async (data, _, variables) => {
        await queryClient.invalidateQueries([QueryKey.Project, data?.project.toString()]);
        const project = await queryClient
          .getQueryData([QueryKey.Project, variables.id?.toString()]) as ProjectModel;
        queryClient.setQueryData(QueryKey.DataSource, project.dataSources);
      },
      onError: async (error: Error, project: Partial<CreateDataSourceModel>, context: unknown) => {
        const rollback = context;
        if (typeof rollback === 'function') {
          rollback();
          SnackBarUtils.toast({ id: project.id?.toString(), message: error.message });
        }

        await queryClient.invalidateQueries([QueryKey.Project, project.id?.toString()]);
      },
    },
  );
}

export function useUpdateSource(): MutationResult<Partial<CreateDataSourceModel>> {
  const queryClient = useQueryClient();

  return useMutation(
    (dataSource: Partial<CreateDataSourceModel>) => api.updateSourceBy(
      dataSource as Required<CreateDataSourceModel>,
    ),
    {
      onSettled: async (data) => {
        await queryClient.invalidateQueries([QueryKey.DataSource]);
        await queryClient.invalidateQueries([QueryKey.Project, data?.project.toString()]);
      },
      onError: async (error: Error, project: Partial<CreateDataSourceModel>, context: unknown) => {
        const rollback = context;
        if (typeof rollback === 'function') {
          rollback();
          SnackBarUtils.toast({ id: project.id?.toString(), message: error.message });
        }

        await queryClient.invalidateQueries([QueryKey.Project, project.id?.toString()]);
      },
    },
  );
}

export function useDeleteFile(): DeleteMutationResult {
  const queryClient = useQueryClient();

  return useMutation(
    ({ id }: { id: string, projectId: string; }) => api.deleteDataSourceById(id),
    {
      onSettled: async (_, __, variables) => {
        await queryClient.invalidateQueries([QueryKey.Project, variables.projectId.toString()]);
        await queryClient.invalidateQueries([QueryKey.DataSource]);
      },
      onError: async (error: Error, { id, projectId }) => {
        SnackBarUtils.toast({ id, message: error.message });
        await queryClient.invalidateQueries([QueryKey.Project, projectId.toString()]);
      },
    },
  );
}

export function useGetSourceBy(
  { id, projectId }: { id: string; projectId: number; },
): UseQueryResult<CreateDataSourceModel, Error> {
  const queryClient = useQueryClient();
  const { data: project } = useGetProjectById();

  return useQuery(
    [QueryKey.DataSource, id],
    (input) => api.getSourceBy(input.queryKey[1] as string),
    {
      refetchOnWindowFocus: true,
      enabled: false,
      refetchInterval: 4000,

      onSettled: async (data) => {
        if (data?.innerStatus === 'ready_for_extraction' || data?.innerStatus === 'extracted') {
          const everysourceExtracted = project.dataSources.every((s) => s.innerStatus === 'extracted');
          if (everysourceExtracted) {
            await queryClient.invalidateQueries([QueryKey.Project, projectId.toString()]);
          }
        }
      },
      onError: async () => {
        await relogin();
      },
    },
  );
}

export function useStartExtraction(): UseMutationResult<Detail, Error, ExtractionModel, unknown> {
  const queryClient = useQueryClient();

  return useMutation(
    (extractionModel: ExtractionModel) => api.startExtraction({ extractionModel }),
    {
      onSettled: async (_, error, extractionModel, rollback) => {
        queryClient.removeQueries([QueryKey.Report]);
        await queryClient.invalidateQueries([QueryKey.Project, extractionModel.project.toString()]);

        if (typeof rollback === 'function') {
          rollback();
          SnackBarUtils.toast({ id: extractionModel.project?.toString(), message: error?.message });
        }
      },
      onError: async (error: Error, extractionModel: ExtractionModel, context: unknown) => {
        const rollback = context;
        if (typeof rollback === 'function') {
          rollback();
          SnackBarUtils.toast({ id: extractionModel.project?.toString(), message: error.message });
        }

        await queryClient.invalidateQueries([QueryKey.Project, extractionModel.project?.toString()]);
      },
    },
  );
}
