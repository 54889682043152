import { Suspense } from 'react';

import CircularSpinner from 'common/components/circular-spinner/circular-spinner';

const withSuspense = (Component: JSX.Element): JSX.Element => (
  <Suspense fallback={<CircularSpinner size="large" color="primary" />}>
    {Component}
  </Suspense>
);

export default withSuspense;
