import { useContext } from 'react';
import { ModalContext, ModalContextType } from './ModalContext';

import { TabOrientationContext, TabOrientationContextType } from './TabOrientationContext';
import { TabsContext, TabsContextType } from './TabsContext';
import { TooMuchFilesContext, TooMuchFilesContextType } from './TooMuchFilesContext';

export function useSetTabOrientationContext(): TabOrientationContextType {
  const context = useContext(TabOrientationContext);

  if (context === undefined) {
    throw new Error('TextManagerContextProvider must be used within a CreateOrEditProjectPage');
  }

  return context;
}

export function useModalContext(): ModalContextType {
  const context = useContext(ModalContext);

  if (context === undefined) {
    throw new Error('ModalContextProvider must be used within a CreateOrEditProjectPage');
  }

  return context;
}

export function useSetTooMuchFilesContext(): TooMuchFilesContextType {
  const context = useContext(TooMuchFilesContext);

  if (context === undefined) {
    throw new Error('TooMuchFilesContextProvider must be used within a CreateOrEditProjectPage');
  }

  return context;
}

export function useTabsContext(): TabsContextType {
  const context = useContext(TabsContext);

  if (context === undefined) {
    throw new Error('TabsContextProvider must be used within a CreateOrEditProjectPage');
  }

  return context;
}
