import sc from '@mui/styled-engine';
import { alpha } from '@mui/material/styles';

import { AppBar, Button, InputBase } from '@mui/material';

import { GLOBAL_BACKGROUND } from 'common/utils/constants';

export const SearchStyled = sc('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.black, 0.03),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.black, 0.01),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

export const InputSearchStyled = sc(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1.5, 1, 1.5, 0),
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: theme.shape.borderRadius,
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '24ch',
      '&:focus': {
        width: '30ch',
      },
    },
  },
}));

export const AppBarStyled = sc(AppBar)({
  height: '80px',
  justifyContent: 'center',
  background: GLOBAL_BACKGROUND,
});

export const SearchIconWrapperStyled = sc('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const ButtonStyled = sc(Button)(({ theme }) => ({
  padding: theme.spacing(1.5, 5),
}));
