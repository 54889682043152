import { memo } from 'react';

import {
  Box, Button, CardContent, Typography,
  Checkbox, Divider, FormControlLabel, Slider,
} from '@mui/material';

import ModalComponent from 'common/components/modal-component';
import { ButtonText } from 'common/components/create-edit-page/utils/constants';
import { LanguageModel } from 'common/components/create-edit-page/lib/models/language.model';

import { useSetExtractionToSend } from './utils/useSetExtractionToSend';
import {
  ControlLabel, DEFAULT_SLIDER_VALUE, marks, ModalText,
} from './utils/constants';

type ExtractionSettingModalProps = {
  language: LanguageModel | undefined
};

function ExtractionSettingModal({ language }: ExtractionSettingModalProps): JSX.Element | null {
  const isNamedEntityEnabled = language?.supportNer;
  const isAbbreviationEnabled = language?.supportAbbr;
  const isSpecificTermsEnabled = language?.supportSt;

  const {
    extraction,
    changeCommitedHandle,
    handleChangeCheckbox,
    sendToExtraction,
    valueLabelFormat,
    valueText,
    handleClose,
  } = useSetExtractionToSend(language);

  return (
    <ModalComponent title={ModalText.Title}>
      <CardContent>
        <Typography sx={{ mt: 2, mb: 4 }}>
          {ModalText.Subtitle}
        </Typography>
        <Box sx={{ display: 'inline-flex' }}>
          {isNamedEntityEnabled && (
            <FormControlLabel
              sx={{ pr: 4 }}
              label={ControlLabel.NamedEntity}
              control={(
                <Checkbox
                  checked={extraction.extractTypes.includes('ner')}
                  onChange={handleChangeCheckbox}
                  name="ner"
                />
              )}
            />
          )}
          {isAbbreviationEnabled && (
            <FormControlLabel
              sx={{ pr: 4 }}
              label={ControlLabel.Abbreviation}
              control={(
                <Checkbox
                  checked={extraction.extractTypes.includes('abbr')}
                  onChange={handleChangeCheckbox}
                  name="abbr"
                />
              )}
            />
          )}
          {isSpecificTermsEnabled && (
            <FormControlLabel
              label={ControlLabel.SpecificTerm}
              control={(
                <Checkbox
                  checked={extraction.extractTypes.includes('st')}
                  onChange={handleChangeCheckbox}
                  name="st"
                />
              )}
            />
          )}
        </Box>
        {
          isSpecificTermsEnabled && (
            <>
              <Typography sx={{ mt: 4 }}>
                {ModalText.Sensitivity}
              </Typography>
              <Slider
                aria-label="Sensitivity"
                defaultValue={DEFAULT_SLIDER_VALUE}
                getAriaValueText={valueText}
                valueLabelDisplay="auto"
                valueLabelFormat={valueLabelFormat}
                onChangeCommitted={changeCommitedHandle}
                marks={marks}
                min={0}
                max={15}
                sx={{ mt: 12 }}
              />
            </>
          )
        }

      </CardContent>
      <Divider variant="fullWidth" />
      <Box
        p={4}
        display="flex"
        justifyContent="flex-end"
        sx={{ minWidth: 486 }}
      >
        <Button
          onClick={handleClose}
          variant="outlined"
          sx={{ pl: 4, pr: 4, mr: 4 }}
        >
          {ButtonText.Cancel}
        </Button>
        <Button
          disabled={!extraction.extractTypes.length}
          onClick={sendToExtraction}
          variant="contained"
          sx={{ pl: 4, pr: 4 }}
        >
          {ButtonText.Ok}
        </Button>
      </Box>
    </ModalComponent>
  );
}

export default memo(ExtractionSettingModal);
