import { memo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { Button, FormControl, Typography } from '@mui/material';

import
ReactHookFormTextFieldOutlined
  from 'common/components/react-hook-forms/ReactHookFormTextFieldOutlined';
import { LocationState } from 'common/utils/location-from';
import { AppRoute } from 'common/components/routes/utils/constants';

import { InputFieldLabel, InputFieldName, LoginPageText } from './utils/constants';
import { FormValues } from './utils/types';
import { useAuth } from './lib/auth';

import { LoginContainerStyled } from './utils/styled';

function Login(): JSX.Element {
  const { control, handleSubmit } = useForm<FormValues>({ mode: 'onChange' });
  const { login } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const from = (location.state as LocationState)?.from?.pathname || AppRoute.Default;

  const onSubmit = async (data: FormValues): Promise<void> => {
    const user = await login(data);

    if (user) navigate(from, { replace: true });
  };

  return (
    <FormControl
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        height: '100%',
      }}
    >
      <LoginContainerStyled spacing={5}>
        <Typography variant="h3" fontWeight="bold">
          {LoginPageText.Login}
        </Typography>
        <FormControl fullWidth>
          <ReactHookFormTextFieldOutlined
            control={control}
            label={InputFieldLabel.Email}
            name={InputFieldName.Email}
            type="email"
          />
        </FormControl>
        <FormControl fullWidth>
          <ReactHookFormTextFieldOutlined
            control={control}
            label={InputFieldLabel.Password}
            name={InputFieldName.Password}
            type="password"
          />
        </FormControl>
        <Button
          type="submit"
          variant="contained"
          fullWidth
        >
          {LoginPageText.Login}
        </Button>
      </LoginContainerStyled>
    </FormControl>
  );
}

export default memo(Login);
