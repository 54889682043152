import { Status } from 'containers/project-list-page/utils/enums';
import { ExtractionType } from '../../utils/types';

export function isNoDataSources(dataSourcesCount: number | undefined): boolean {
  return dataSourcesCount === 0;
}

export function isInExtraction(status: `${Status}` | undefined): boolean {
  return status === 'Extracting';
}

export function isDone(status: `${Status}` | undefined): boolean {
  return status === 'Done';
}

export function isReadyForExtraction(status: `${Status}` | undefined): boolean {
  return status === 'Ready for extraction';
}

export function isAttentionRequired(status: `${Status}` | undefined): boolean {
  return status === 'Attention required';
}

export function modifyExtractionTypes(
  extractionTypes: ExtractionType[],
  elementName: string,
  checked: boolean,
): ExtractionType[] {
  const prevExtractionTypes = [...extractionTypes];
  const index = prevExtractionTypes.findIndex((type) => type === elementName);

  if (!checked) {
    prevExtractionTypes.splice(index, 1);
  } else {
    prevExtractionTypes.push(elementName as ExtractionType);
  }

  return prevExtractionTypes;
}
