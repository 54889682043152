import { ModalAction, ModalState } from './ModalContext';
import { TabOrientationAction, TabOrientationState } from './TabOrientationContext';
import { TabsAction, TabsState } from './TabsContext';
import { TooMuchFilesAction, TooMuchFilesState } from './TooMuchFilesContext';

function setOrientationReducer(
  state: TabOrientationState,
  action: TabOrientationAction,
): TabOrientationState {
  const copy = { ...state };
  copy.tabOrientedTo = action.setOrientationTo;

  return copy;
}

function setOpenModalReducer(
  state: ModalState,
  action: ModalAction,
): ModalState {
  const copy = { ...state };
  copy.isOpen = action.open;
  copy.id = action.id;

  return copy;
}

function setTooMuchFilesReducer(
  state: TooMuchFilesState,
  action: TooMuchFilesAction,
): TooMuchFilesState {
  const copy = { ...state };
  copy.files = action.setFiles;
  copy.parentIds = action.setParents;

  return copy;
}

function setTabValueReducer(
  state: TabsState,
  action: TabsAction,
): TabsState {
  const copy = { ...state };
  copy.tabValue = action.set;

  return copy;
}

export {
  setOrientationReducer,
  setOpenModalReducer,
  setTooMuchFilesReducer,
  setTabValueReducer,
};
