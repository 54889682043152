import { Mark } from '@mui/material';

export const enum ModalText {
  Title = 'Extraction settings',
  Subtitle = 'Terms type',
  Sensitivity = 'Sensitivity of specific terms'
}

export const enum ControlLabel {
  NamedEntity = 'Named entity',
  Abbreviation = 'Abbreviation',
  SpecificTerm = 'Specific term',
}

export const marks = [
  {
    value: 0,
    label: 'More terms',
  },
  {
    value: 4,
    label: 'Default',
  },
  {
    value: 15,
    label: 'Less terms',
  },
] as Mark[];

export const DEFAULT_SLIDER_VALUE = 4 as const;
