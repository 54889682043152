import { memo, useEffect, useState } from 'react';

import { IconButton, Menu, MenuItem } from '@mui/material';

import { useGetLanguageList } from 'common/components/create-edit-page/lib/query-hooks';
import { DataSource } from 'common/utils/models/data-source';

import { useUpdateSource } from '../../text-manager-tab/lib/query-hooks';

interface SourceLanguageSelectProps {
  source: DataSource
}

function SourceLanguageSelect({ source }: SourceLanguageSelectProps): JSX.Element {
  const { data: languages } = useGetLanguageList();
  const { mutateAsync } = useUpdateSource();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [languageState, setLanguageState] = useState(source.detectedLanguage);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = async (event: React.MouseEvent<HTMLLIElement>): Promise<void> => {
    const selectedLanguage = event.currentTarget.dataset.codeValue ?? source.detectedLanguage;
    setLanguageState(selectedLanguage);
    event.stopPropagation();
    setAnchorEl(null);
    await mutateAsync({
      ...source,
      detectedLanguage: selectedLanguage,
    });
  };

  useEffect(() => {
    setLanguageState(source.detectedLanguage);
  }, [source.detectedLanguage]);

  return (
    <>
      <IconButton
        aria-controls={open ? 'positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{ fontSize: 16, color: 'primary.main', marginLeft: -1.7 }}
      >
        {languageState || '-'}
      </IconButton>
      <Menu
        id="positioned-menu"
        aria-labelledby="positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        sx={{ maxHeight: 320 }}
      >
        {
          languages?.map((language) => (
            <MenuItem
              key={language.code}
              data-code-value={language.code}
              onClick={handleClose}
            >
              {language.slug}
            </MenuItem>
          ))
        }
      </Menu>
    </>
  );
}

export default memo(SourceLanguageSelect);
