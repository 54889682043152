import { Navigate, useLocation, useRoutes } from 'react-router-dom';

import { useAuth } from 'containers/authentication/lib/auth';

import CircularSpinner from '../circular-spinner/circular-spinner';

import { routes } from './utils/routes';
import { AppRoute } from './utils/constants';

const Routing = function (): JSX.Element | null {
  const { user, isLoggingIn, isRegistering } = useAuth();

  const location = useLocation();

  const element = useRoutes(routes);

  if (location.pathname === '/' || location.pathname === '') {
    return <Navigate to={AppRoute.Default} />;
  }

  if (!user && (isLoggingIn || isRegistering)) {
    return <CircularSpinner size="large" color="primary" />;
  }

  return element;
};

export default Routing;
