import { defaultTheme } from '../defaultTheme';

const MuiCalendarPicker = {
  styleOverrides: {
    root: {
      background: 'rgb(254 254 254)',
      borderRadius: defaultTheme.shape.borderRadius,
    },
  },
};

export default MuiCalendarPicker;
