import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm, UseFormReturn } from 'react-hook-form';
import uniqBy from 'lodash/uniqBy';
import dayjs from 'dayjs';

import useGetProjectById from 'common/query-hooks/use-get-project-by-id';
import SnackBarUtils from 'common/SnackBar/SnackBarUtils';
import { useCreateProject, useUpdateProject } from 'containers/create-project-page/lib/query-hooks';
import {
  useSetCreateOrUpdateProjectContext,
  useUsersContext,
} from 'containers/create-project-page/local-state/hooks';
import { useGetLanguageList } from '../lib/query-hooks';
import { useSendEmailContext } from '../local-state/hooks';

import { ProjectFieldValues } from '../components/utils/types';
import { Operation } from './types';
import { DATES_MESSAGE } from './constants';
import { LanguageModel } from '../lib/models/language.model';

interface CreateOrUpdateOutput {
  formProps: UseFormReturn<ProjectFieldValues, object>;
  onCreateOrUpdateProjectHandle: (data: ProjectFieldValues) => Promise<void>;
  operationType: Operation;
}

export const useCreateUpdateProject = (): CreateOrUpdateOutput => {
  const navigate = useNavigate();

  const { state: { value: operationType } } = useSetCreateOrUpdateProjectContext();
  const { state: { defaultUsers }, dispatch: dispatchUsers } = useUsersContext();
  const { state: { value: usersToSendEmail }, dispatch: usersToSendDispatch } = useSendEmailContext();

  const { data: project } = useGetProjectById();
  const { data: languages } = useGetLanguageList();
  const updateProject = useUpdateProject();
  const createProject = useCreateProject();

  const formProps = useForm<ProjectFieldValues>({ mode: 'onChange', criteriaMode: 'all' });

  const [languagesState, setLanguagesState] = useState<LanguageModel[] | undefined>(languages);

  useEffect(() => {
    if (languages?.length) {
      setLanguagesState(languages);
    }
  }, [languages]);

  useEffect(() => {
    if (operationType === 'edit') {
      formProps.reset({
        projectName: project?.projectName || '',
        projectLanguage: project?.projectLanguage || '',
        description: project?.description || '',
        startDate: project?.startDate || dayjs().toISOString(),
        endDate: project?.endDate || dayjs().toISOString(),
        canSee: project?.canSee || [],
      });
    } if (operationType === 'create' || operationType === null) {
      formProps.reset({
        canSee: [],
        description: '',
        endDate: dayjs().toISOString(),
        startDate: dayjs().toISOString(),
        projectLanguage: '',
        projectName: '',
      });
      dispatchUsers({ type: 'set-existed-users', users: [] });
      dispatchUsers({ type: 'set-selected-users', users: [] });
    }
  }, [formProps, project?.canSee, project?.description, project?.endDate,
    project?.projectLanguage, project?.projectName, project?.startDate, operationType, dispatchUsers]);

  const onCreateOrUpdateProjectHandle = async (data: ProjectFieldValues): Promise<void> => {
    if (data.startDate > data.endDate) {
      return SnackBarUtils.toast({ actionType: 'delete', message: DATES_MESSAGE });
    }

    const usersToSend = uniqBy([
      ...usersToSendEmail,
      ...defaultUsers.map((user) => ({ ...user, sendInvite: false })),
    ], 'email');

    const newSelectedLanguageCode = languagesState
      ?.find((language) => language.slug === data.projectLanguage)
      ?.code;

    const newSelectedLanguageCodeFromName = languagesState
      ?.find((language) => language.code === data.projectLanguage)
      ?.code;

    const defaultRequestData = {
      ...data,
      canSee: usersToSend,
      startDate: dayjs(data.startDate).format('YYYY-MM-DD'),
      endDate: dayjs(data.endDate).format('YYYY-MM-DD'),
      projectLanguage: newSelectedLanguageCodeFromName || newSelectedLanguageCode,
    } as ProjectFieldValues;

    if (!newSelectedLanguageCode && !newSelectedLanguageCodeFromName) {
      return SnackBarUtils.toast({
        message: 'Please fill in the required fields',
        actionType: 'delete',
      });
    }

    if (operationType === 'edit' && project?.id) {
      const request = await updateProject.mutateAsync({ ...defaultRequestData, id: project.id });
      if (request) {
        navigate(-1);
        usersToSendDispatch({ field: { type: 'drop-results' } });
        dispatchUsers({ type: 'set-selected-users', users: [] });
      } else SnackBarUtils.toast({ id: project.id.toString(), message: 'Something went wrong. Try again' });
    } else {
      const request = await createProject.mutateAsync({ ...defaultRequestData });

      if (request.id) {
        dispatchUsers({ type: 'set-selected-users', users: [] });
        formProps.reset();
        usersToSendDispatch({ field: { type: 'drop-results' } });
      } else SnackBarUtils.toast({ id: '', message: 'Something went wrong. Try again' });
    }

    return SnackBarUtils.toast({
      actionType: 'delete',
      message: operationType === 'edit' ? 'Project updated' : 'Project created',
    });
  };

  return {
    onCreateOrUpdateProjectHandle,
    formProps,
    operationType,
  };
};
