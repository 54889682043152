import { useEffect, useState } from 'react';

import useDebounce from 'common/hooks/useDebounse';
import { DataSource } from 'common/utils/models/data-source';

import { useUpdateSource } from '../../lib/query-hooks';

interface UpadateDebouncedText {
  textSetHandle: ({ currentTarget }: React.FocusEvent<HTMLSpanElement>) => void;
  textChangeHandle: ({ currentTarget }: React.FormEvent) => void;
}

const useUpadateDebouncedText = ({ source }: { source: DataSource; }): UpadateDebouncedText => {
  const { mutateAsync } = useUpdateSource();

  const [text, setText] = useState('');
  const debouncedValue = useDebounce<string>(text, 500);

  const textSetHandle = ({ currentTarget }: React.FocusEvent<HTMLSpanElement>): void => {
    if (currentTarget?.textContent) {
      setText(currentTarget.textContent);
    }
  };

  const textChangeHandle = ({ currentTarget }: React.FormEvent): void => {
    if (currentTarget?.textContent) {
      setText(currentTarget.textContent);
    }
  };

  useEffect(() => {
    if (debouncedValue) {
      mutateAsync({
        ...source,
        text: debouncedValue,
        detectedLanguage: undefined, // For avoiding error in the if statement in api file
      });
    }
  }, [debouncedValue, mutateAsync, source]);

  return {
    textSetHandle,
    textChangeHandle,
  };
};

export default useUpadateDebouncedText;
