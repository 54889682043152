import { useLocation } from 'react-router-dom';

import { Box, Grid, Stack } from '@mui/material';

import { useModalContext } from 'containers/project-manager-page/local-state/hooks';
import { TabRoute } from 'common/components/routes/utils/constants';
import CircularSpinner from 'common/components/circular-spinner/circular-spinner';

import { useCallback } from 'react';
import { ButtonText } from '../../utils/constants';
import TextManagerInfo from '../additional-components/TextManagerInfo';
import useBlockTab from './utils/useLockExtraction';

import Files from './files';
import URLs from './urls';
import Texts from './text';

import { ButtonStyled, TextManagerTabPanelStyled } from '../utils/styled';
import ExtractionSettingModal from './ExtractionSettingModal';
import { useCurrentProjectLanguage } from './utils/useCurrentProjectLanguage';

function TextManagerTab(): JSX.Element {
  const { dispatch: modalDispatch, state: { id } } = useModalContext();

  const location = useLocation();

  const { isLoading, language } = useCurrentProjectLanguage();
  const { blockIfNotReadyForExtraction } = useBlockTab();

  const sendToExtractionHandle = useCallback((): void => {
    modalDispatch({ open: true, id: 'extraction' });
  }, [modalDispatch]);

  if (isLoading) {
    return <CircularSpinner size="large" color="primary" />;
  }

  return (
    <>
      {location.pathname.includes(TabRoute.TextManager) && (
        <Grid
          container
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <TextManagerInfo />
          <ButtonStyled
            disabled={blockIfNotReadyForExtraction}
            variant="contained"
            onClick={sendToExtractionHandle}
          >
            {ButtonText.SentToExtraction}
          </ButtonStyled>
        </Grid>
      )}
      <TextManagerTabPanelStyled value={TabRoute.TextManager}>
        <Grid>
          <Box mt={2}>
            <Files />
          </Box>
          <Stack mt={8}>
            <URLs />
          </Stack>
          <Stack mt={8} direction="column" display="flex">
            <Texts />
          </Stack>
        </Grid>
      </TextManagerTabPanelStyled>
      { id === 'extraction' && <ExtractionSettingModal language={language} />}
    </>
  );
}

export default TextManagerTab;
