import React, { createRef, useCallback } from 'react';
import { SnackbarKey, SnackbarMessage, SnackbarProvider } from 'notistack';

import { SnackbarUtilsConfigurator } from 'common/SnackBar/SnackBarUtils';
import SnackMessage from 'common/SnackBar/CustomSnack';
import { SnackContent } from 'common/SnackBar/types';

export function AppSnackbarProvider({ children }: {children: React.ReactElement}): JSX.Element {
  const notistackRef = createRef<SnackbarProvider>();

  const MemoizedContent = useCallback(
    (key: SnackbarKey, message: SnackbarMessage | Partial<SnackContent>) => (
      <SnackMessage id={key} message={message as Partial<SnackContent>} />
    ),
    [],
  );

  return (
    <SnackbarProvider
      maxSnack={3}
      ref={notistackRef}
      dense
      preventDuplicate
      content={MemoizedContent}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <SnackbarUtilsConfigurator />
      {children}
    </SnackbarProvider>
  );
}
