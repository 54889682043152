import { memo } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import type { RouteProps } from 'react-router-dom';

import { useAuth } from 'containers/authentication/lib/auth';

import { AppRoute } from './utils/constants';

interface PrivateRouteProps extends RouteProps {
  children: JSX.Element
}

export const PrivateRoute = memo(({ children }: PrivateRouteProps):JSX.Element => {
  const { user } = useAuth();
  const location = useLocation();

  if (!user) {
    return <Navigate to={AppRoute.Login} state={{ from: location }} replace />;
  }

  return children;
});
