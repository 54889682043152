import { memo } from 'react';

import { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIos';

import { MuiAccordionSummaryStyled } from './styled';

function AccordionSummary(props: AccordionSummaryProps): JSX.Element {
  return (
    <MuiAccordionSummaryStyled
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '1.5rem' }} />}
      {...props}
    />
  );
}

export default memo(AccordionSummary);
