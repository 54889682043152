import { Grid, Typography } from '@mui/material';
import { Control } from 'react-hook-form';

import
ReactHookFormTextFieldOutlined
  from 'common/components/react-hook-forms/ReactHookFormTextFieldOutlined';

import { InputText } from '../utils/constants';
import { getKeyOf, ProjectFieldValues } from './utils/types';

interface DescriptionProps {
  control?: Control<ProjectFieldValues, object>,
}

function Description({ control }: DescriptionProps): JSX.Element | null {
  if (!control) return null;

  return (
    <Grid item xs={12} pt={6}>
      <Typography pb={2} variant="subtitle1">
        {InputText.Description}
      </Typography>
      <ReactHookFormTextFieldOutlined
        label="Project description"
        name={getKeyOf<ProjectFieldValues>('description')}
        type="text"
        control={control}
        notched={false}
        fullWidth
        multiline
        rows={5}
        sx={{ pt: 1, pb: 1 }}
      />
    </Grid>
  );
}

export default Description;
