/* eslint-disable @typescript-eslint/no-explicit-any */
import { Select, SelectProps } from '@mui/material';
import { Control, Controller, FieldValues } from 'react-hook-form';

interface ReactHookFormSelectProps<T> extends SelectProps {
  name: string;
  control: Control<T, object>;
  children: React.ReactNode;
}

function ReactHookFormSelect<T extends FieldValues>({
  name,
  control,
  children,
  ...props
}: ReactHookFormSelectProps<T | any>): JSX.Element {
  return (
    <Controller<T | FieldValues>
      render={({ field }) => (
        <Select
          {...field}
          {...props}
          sx={{ display: 'flex' }}
        >
          {children}
        </Select>
      )}
      name={name}
      control={control}
      defaultValue=""
    />
  );
}
export default ReactHookFormSelect;
