import { useMemo } from 'react';

import { Chip } from '@mui/material';

import { MUIChipSystemColor } from 'common/utils/color-type';
import { Status } from '../utils/enums';

function StatusChip({ status }: { status:`${Status}` }): JSX.Element {
  const color = useMemo(() => {
    const colored = {
      'In progress': 'warning',
      'Attention required': 'error',
      'Ready for extraction': 'primary',
      Extracting: 'secondary',
      Done: 'success',
    } as {[key in `${Status}`]: MUIChipSystemColor};

    return colored[status];
  }, [status]);

  return <Chip label={status} color={color} size="small" sx={{ ml: 3.5 }} />;
}

export default StatusChip;
