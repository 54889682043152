// import { StrictMode } from 'react'; // FIXME: Known issue with strict mode react-virtualized
import { BrowserRouter } from 'react-router-dom';

import { StyledEngineProvider as MuiThemeProvider } from '@mui/styled-engine';
import { CssBaseline, NoSsr, ThemeProvider } from '@mui/material';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import theme from 'common/theme';
import GlobalStyle from 'common/theme/css/globalStyle';
import ApplicationBar from 'common/components/app-bar';
import Routing from 'common/components/routes/Routing';
import ReactQueryProvider from 'common/providers/ReactQueryProvider';
import { AppSnackbarProvider } from 'common/providers/AppSnackbarProvider';
import AppContextProvider from 'common/providers/AppContextProvider';

import { AppContainerStyled } from './styled';

function App():JSX.Element {
  return (
    <NoSsr>
      {/* <StrictMode> */}
      <AppContextProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <CssBaseline />
          <MuiThemeProvider>
            <ThemeProvider theme={theme}>
              <GlobalStyle />
              <ReactQueryProvider>
                <BrowserRouter>
                  <AppSnackbarProvider>
                    <>
                      <ApplicationBar />
                      <AppContainerStyled>
                        <Routing />
                      </AppContainerStyled>
                    </>
                  </AppSnackbarProvider>
                </BrowserRouter>
              </ReactQueryProvider>
            </ThemeProvider>
          </MuiThemeProvider>
        </LocalizationProvider>
      </AppContextProvider>
      {/* </StrictMode> */}
    </NoSsr>
  );
}

export default App;
