import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import useGetProjectById from 'common/query-hooks/use-get-project-by-id';
import { LanguageModel } from 'common/components/create-edit-page/lib/models/language.model';
import { AppRoute, TabRoute } from 'common/components/routes/utils/constants';
import { useModalContext } from 'containers/project-manager-page/local-state/hooks';

import { useStartExtraction } from '../lib/query-hooks';
import { ExtractionModel } from '../lib/types';
import { DEFAULT_SLIDER_VALUE } from './constants';
import { modifyExtractionTypes } from './helpers';
import { ExtractionType } from '../../utils/types';

interface SetExtractionToSendResult {
  extraction: ExtractionModel;
  handleChangeCheckbox: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  valueLabelFormat: (value: number) => number;
  sendToExtraction: () => Promise<void>;
  changeCommitedHandle: (_: Event | React.SyntheticEvent, value: number | number[]) => void;
  valueText: (value: number) => string;
  handleClose: VoidFunction;
}

export const useSetExtractionToSend = (language: LanguageModel | undefined): SetExtractionToSendResult => {
  const { dispatch: modalDispatch } = useModalContext();

  const navigate = useNavigate();
  const { mutateAsync } = useStartExtraction();
  const { projectId } = useGetProjectById();

  const [extraction, setExtraction] = useState<ExtractionModel>({
    project: projectId,
    stSensitivity: DEFAULT_SLIDER_VALUE,
    extractTypes: [
      language?.supportNer && 'ner',
      language?.supportAbbr && 'abbr',
      language?.supportSt && 'st',
    ].filter((type) => type) as ExtractionType[],
  });

  const handleChangeCheckbox = useCallback((
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ): void => {
    setExtraction((prev) => ({
      ...prev,
      extractTypes: modifyExtractionTypes(prev.extractTypes, event.target.name, checked),
    }));
  }, []);

  const handleClose = useCallback((): void => {
    modalDispatch({ open: false, id: 'closen' });
  }, [modalDispatch]);

  const valueText = useCallback((value: number): string => value.toString(), []);

  const changeCommitedHandle = useCallback((
    _: Event | React.SyntheticEvent,
    value: number | number[],
  ): void => {
    setExtraction((prev) => ({
      ...prev,
      stSensitivity: value as number,
    }));
  }, []);

  const valueLabelFormat = useCallback((value: number): number => value, []);

  const sendToExtraction = useCallback(async (): Promise<void> => {
    const result = await mutateAsync(extraction);
    navigate(`${AppRoute.Default}/${projectId}/${TabRoute.Results}`);
    if (result) {
      handleClose();
    }
  }, [extraction, handleClose, mutateAsync, navigate, projectId]);

  return {
    extraction,
    handleChangeCheckbox,
    valueLabelFormat,
    sendToExtraction,
    changeCommitedHandle,
    valueText,
    handleClose,
  };
};
