import { useContext } from 'react';
import { SendEmailContext, SendEmailContextType } from './SendEmailContext';

export function useSendEmailContext(): SendEmailContextType {
  const context = useContext(SendEmailContext);

  if (context === undefined) {
    throw new Error('SendEmailContext must be used within a CreateOrEditProjectPage');
  }

  return context;
}
