import { AxiosError, AxiosResponse } from 'axios';

import axiosInstance from 'common/interceptors/axios';
import { DataSource } from 'common/utils/models/data-source';

import * as helper from './helpers';
import { Detail, ExtractionModel } from './types';
import Endpoint from './Endpoint';
import { CreateDataSourceModel } from './models/CreateDataSource.model';

const axios = axiosInstance;

export const createDataSource = async (
  dataSource: Required<CreateDataSourceModel>,
): Promise<Required<CreateDataSourceModel>> => new Promise<Required<CreateDataSourceModel>>(
  (resolve, reject) => {
    let formData;

    if (dataSource) {
      if (dataSource.file) {
        formData = helper.prepareFileFormData(dataSource);
      } if (dataSource.text) {
        formData = helper.prepareTextFormData(dataSource);
      }
    }

    axios.post<Required<CreateDataSourceModel>>(
      Endpoint.CreateDataSources,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          accept: 'application/json',
        },
      },
    )
      .then((response: AxiosResponse<Required<CreateDataSourceModel>>) => resolve(response.data))
      .catch((error: AxiosError<string>) => reject(error));
  },
);

export const createUrlsDataSource = async (
  dataSource: Required<CreateDataSourceModel>,
): Promise<Required<CreateDataSourceModel>> => new Promise<Required<CreateDataSourceModel>>(
  (resolve, reject) => {
    let formData;

    if (dataSource?.sourceLocation) {
      formData = helper.prepareUrlFormData(dataSource);
    }

    axios.post<Required<CreateDataSourceModel>>(
      Endpoint.CreateDataSources,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          accept: 'application/json',
        },
      },
    )
      .then((response: AxiosResponse<Required<CreateDataSourceModel>>) => resolve(response.data))
      .catch((error: AxiosError<string>) => reject(error));
  },
);

export const deleteDataSourceById = async (
  id: string,
): Promise<Required<CreateDataSourceModel>> => new Promise<Required<CreateDataSourceModel>>(
  (resolve, reject) => {
    axios.delete<Required<CreateDataSourceModel>>(`${Endpoint.DeleteDataSources}/${id}`)
      .then((response: AxiosResponse<Required<CreateDataSourceModel>>) => resolve(response.data))
      .catch((error: AxiosError<string>) => reject(error));
  },
);

export const getSourceBy = async (id: string): Promise<DataSource> => new Promise<DataSource>(
  (resolve, reject) => {
    axios.get<DataSource>(`${Endpoint.GetDataSource}/${id}`)
      .then((response: AxiosResponse<DataSource>) => resolve(response.data))
      .catch((error: AxiosError<string>) => reject(error));
  },
);

export const updateSourceBy = async (
  source: Required<CreateDataSourceModel>,
): Promise<DataSource> => new Promise<DataSource>(
  (resolve, reject) => {
    let formData;

    if (source) {
      if (source.file) {
        formData = helper.prepareFileFormData(source);
      } if (source.text) {
        formData = helper.prepareTextFormData(source);
      } if (source.detectedLanguage) {
        formData = helper.prepareLanguageFormData(source);
      }
    }

    axios.patch<DataSource>(
      `${Endpoint.UpdateDataSource}/${source.id}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          accept: 'application/json',
        },
      },
    )
      .then((response: AxiosResponse<DataSource>) => resolve(response.data))
      .catch((error: AxiosError<string>) => reject(error));
  },
);

export const startExtraction = async (
  { extractionModel }: { extractionModel: ExtractionModel; },
): Promise<Detail> => new Promise<Detail>(
  (resolve, reject) => {
    axios.post<Detail>(`${Endpoint.StartExtraction}`, { ...extractionModel })
      .then((response: AxiosResponse<Detail>) => resolve(response.data))
      .catch((error: AxiosError<string>) => reject(error));
  },
);
