import useGetProjectById from 'common/query-hooks/use-get-project-by-id';
import { DataSource, SourceToken } from 'common/utils/models/data-source';
import { useEffect } from 'react';
import { useGetSourceBy } from '../../lib/query-hooks';

interface GetContentResult {
  textContent: string;
  tokens: SourceToken[];
  projectLanguage: string;
  isLoading: boolean;
}

interface GetContentParams {
  source: DataSource;
  expanded: boolean;
}

const useGetDataSource = ({ source, expanded }: GetContentParams): GetContentResult => {
  const { projectId, data: { projectLanguage } } = useGetProjectById();
  const { data, refetch, isLoading } = useGetSourceBy({ id: source.id.toString(), projectId });

  useEffect(() => {
    if (expanded) {
      refetch();
    }
  }, [expanded, refetch, source.id]);

  return {
    textContent: data?.text ?? '',
    tokens: data?.tokens ?? [],
    projectLanguage,
    isLoading,
  };
};

export default useGetDataSource;
