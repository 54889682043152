import { useState } from 'react';
import { SourceFileType } from 'common/utils/models/file-type';

import { ClickAwayListener, Typography } from '@mui/material';

import
ReactHookFormTextFieldOutlined
  from 'common/components/react-hook-forms/ReactHookFormTextFieldOutlined';
import CircularSpinner from 'common/components/circular-spinner/circular-spinner';
import { ButtonText } from 'containers/project-manager-page/utils/constants';
import { useSetTabOrientationContext } from 'containers/project-manager-page/local-state/hooks';

import AccordionItem from '../../additional-components/accordion/AccordionItem';
import { useAddSource } from '../lib/query-hooks';
import { InputPlaceholderText, TextManagerText } from '../../utils/constants';
import { InputFieldName } from './utils/constants';
import { useSendText } from './utils/useSendText';

import {
  TextsPaperStyled, InputWrapperStyled,
  ButtonTextsStyled, ButtonContainerStyled,
} from './utils/styled';

function Texts(): JSX.Element | null {
  const { state: { tabOrientedTo } } = useSetTabOrientationContext();
  const { isLoading: isAdding } = useAddSource();
  const {
    control,
    isTextFieldHidden,
    handleSubmit,
    handleFocuse,
    handleClickAway,
    onSendHandle,
    dataSources,
  } = useSendText();

  const [expanded, setExpanded] = useState<string | false>(false);

  const expandHandle = (panel: string) => (_: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  if (!dataSources?.some((data) => data.type === SourceFileType.Text) && tabOrientedTo === 'results') {
    return null;
  }

  return (
    <>
      <Typography variant="subtitle1">{TextManagerText.Texts}</Typography>
      <ClickAwayListener onClickAway={handleClickAway}>
        <TextsPaperStyled
          onSubmit={handleSubmit(onSendHandle)}
          component="form"
          elevation={3}
        >
          {tabOrientedTo === 'project-manager' && (
            <InputWrapperStyled>
              {isTextFieldHidden && (
                <ReactHookFormTextFieldOutlined
                  id={InputFieldName.SourceName}
                  label={InputPlaceholderText.TextName}
                  name={InputFieldName.SourceName}
                  type="text"
                  control={control}
                  onFocus={handleFocuse}
                  fullWidth
                  sx={{ mt: 2 }}
                />
              )}
              <ReactHookFormTextFieldOutlined
                id={InputFieldName.Text}
                label={InputPlaceholderText.Typing}
                name={InputFieldName.Text}
                type="text"
                rows={isTextFieldHidden ? 7 : 3}
                control={control}
                onFocus={handleFocuse}
                fullWidth
                multiline
                sx={{ mt: 6 }}
              />
              <ButtonContainerStyled>
                {
                  isAdding && (
                    <CircularSpinner
                      size="small"
                      color="primary"
                    />
                  )
                }
                <ButtonTextsStyled variant="outlined" type="submit">
                  {ButtonText.Add}
                </ButtonTextsStyled>
              </ButtonContainerStyled>
            </InputWrapperStyled>
          )}
          {!!dataSources?.length && dataSources.map((source) => (
            source.type === SourceFileType.Text
        && (
          <AccordionItem
            key={source.id}
            source={source}
            expanded={expanded === source.id.toString()}
            expandHandle={expandHandle(`${source.id}`)}
          />
        )
          ))}
        </TextsPaperStyled>
      </ClickAwayListener>
    </>
  );
}

export default Texts;
