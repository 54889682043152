import sc from '@mui/styled-engine';

import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import CachedIcon from '@mui/icons-material/Cached';
import CircularSpinner from 'common/components/circular-spinner/circular-spinner';

import { blue, grey } from '@mui/material/colors';
import { AccordionDetails, Grid } from '@mui/material';

import AccordionSummary from './AccordionSummary';

type UploadProcessType = {
  $isUploading?: boolean;
};

export const AccordionStyled = sc(MuiAccordion)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  '&:before': {
    display: 'none',
  },
}));

export const MuiAccordionSummaryStyled = sc(MuiAccordionSummary)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
    marginTop: 0,
    marginBottom: 0,
    alignItems: 'center',
  },
}));

export const SourceTextStyled = sc(Typography)<{ component?: string; }>(({ theme }) => ({
  whiteSpace: 'pre-wrap',
  wordWrap: 'break-word',
  padding: theme.spacing(1),
  marginBottom: theme.spacing(1),
  maxHeight: '50vh',
  overflow: 'auto',

  '&:focus-visible': {
    backgroundColor: grey[100],
    outline: `2px solid ${theme.palette.primary.main}`,
    borderRadius: theme.shape.borderRadius,
  },
}));

export const CachedIconStyled = sc(CachedIcon)<UploadProcessType>(({ $isUploading, theme }) => ({
  '@keyframes pulsate': {
    from: {
      transform: 'rotate(360deg)',
    },
    to: {
      transform: 'rotate(0deg)',
    },
  },
  animation: $isUploading ? 'pulsate 1s infinite ease' : 'none',
  position: 'absolute',
  margin: theme.spacing(0, 2),
  color: theme.palette.text.disabled,
}));

export const AccordionSummaryStyled = sc(AccordionSummary)<{ $isParentUrlAccordion?: boolean | false; }>(
  ({ $isParentUrlAccordion }) => ({
    '& .MuiAccordionSummary-content': {
      display: 'grid',
      gridTemplateColumns: '5fr 2fr',
      userSelect: 'text',
    },
    backgroundColor: $isParentUrlAccordion ? blue[50] : 'unset',
  }),
);

export const SourceNameContainerStyled = sc(Grid)<{ $isSourceTypeUrl?: boolean; }>(
  ({ $isSourceTypeUrl }) => ({
    display: 'grid',
    gridTemplateColumns: $isSourceTypeUrl ? '32px 1fr' : '1fr',
  }),
);

export const NestedSourceNameContainerStyled = sc(Grid)({
  display: 'grid',
  gridTemplateColumns: '32px 1fr',
});

export const CircularSpinnerStyled = sc(CircularSpinner)(({ theme }) => ({
  marginRight: theme.spacing(1.5),
}));

export const AccordionDetailsStyled = sc(AccordionDetails)(({ theme }) => ({
  paddingRight: theme.spacing(4),
}));
