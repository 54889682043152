/* eslint-disable max-len */
export const enum InputText {
  ProjectName = 'Name',
  ProjectLanguage = 'Language',
  Description = 'Description (optional)',
  StartDate = 'Event start date',
  EndDate = 'Event end date',
  SharedWith = 'Shared with',
  SelectAddUsers = 'Add users',
}

export const enum SharedWithText {
  Name = 'Name',
  SendInvite = 'Send invite',
}

export const enum ButtonText {
  SendInvite = 'Send invite',
  Save = 'Save',
  Create = 'Create',
  Cancel = 'Cancel',
  Ok = 'Ok',
}

export const enum SelectMessage {
  NoOptionsMatch = 'No email matches the filter'
}

export const DATES_MESSAGE = 'Please check Project\'s start and end dates. End date should not be earlier than start date.' as const;
