import { useCallback, useMemo, useState } from 'react';
import { Control, useForm, UseFormHandleSubmit } from 'react-hook-form';

import useGetProjectById from 'common/query-hooks/use-get-project-by-id';
import { SourceFileType } from 'common/utils/models/file-type';
import { DataSource } from 'common/utils/models/data-source';

import { useAddSource } from '../../lib/query-hooks';
import { TextsFormValues } from './types';

interface SendText {
  dataSources: DataSource[] | undefined;
  isTextFieldHidden: boolean;
  control: Control<TextsFormValues, object>;
  handleFocuse: VoidFunction;
  handleClickAway: VoidFunction;
  onSendHandle: ({ source_name, text }: TextsFormValues) => Promise<void>;
  handleSubmit: UseFormHandleSubmit<TextsFormValues>;
}

export const useSendText = (): SendText => {
  const { control, handleSubmit, reset } = useForm<TextsFormValues>({ mode: 'onChange' });

  const { mutateAsync: addAsync } = useAddSource();
  const { data, projectId } = useGetProjectById();

  const [isSecondInputActive, setIsSecondInputActive] = useState(false);

  const dataSources = data?.dataSources;

  const isTextExsist = useMemo(
    () => dataSources?.some((source) => source.type === SourceFileType.Text),
    [dataSources],
  );

  const isTextFieldHidden = useMemo(
    () => !isTextExsist || isSecondInputActive,
    [isSecondInputActive, isTextExsist],
  );

  // eslint-disable-next-line camelcase
  const onSendHandle = useCallback(async ({ source_name, text }: TextsFormValues): Promise<void> => {
    const noNameSpecified = `${text.length > 48 ? text.substring(0, 46) : text}...`;
    const response = await addAsync({
      id: projectId,
      // 🤬
      // eslint-disable-next-line camelcase
      sourceName: source_name || noNameSpecified,
      type: 'txt',
      text,
    });

    if (response) reset();
  }, [addAsync, projectId, reset]);

  const handleFocuse = (): void => {
    setIsSecondInputActive(true);
  };
  const handleClickAway = (): void => {
    setIsSecondInputActive(false);
  };

  return {
    dataSources,
    isTextFieldHidden,
    control,
    handleFocuse,
    handleClickAway,
    onSendHandle,
    handleSubmit,
  };
};
