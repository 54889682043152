import { DataSource } from 'common/utils/models/data-source';
import { memo, useState } from 'react';

import AccordionNestedItem from './AccordionNestedItem';

function AccordionNested({ sources }: {sources: DataSource[]}): JSX.Element {
  const [expanded, setExpanded] = useState<string | false>(false);

  const expandHandle = (panel: string) => (_: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };
  return (
    <>
      {sources.map((source) => (
        source.innerStatus !== 'too_many_files'
        && (
          <AccordionNestedItem
            key={`nested-${source.id}`}
            source={source}
            expanded={expanded === `nested-${source.id}`}
            expandHandle={expandHandle(`nested-${source.id}`)}
          />
        )
      ))}
    </>
  );
}

export default memo(AccordionNested);
