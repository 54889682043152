import { memo } from 'react';

import {
  IconButton, Typography,
  Grid, Tooltip,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import LanguageIcon from '@mui/icons-material/Language';
import { grey } from '@mui/material/colors';

import { useSetTabOrientationContext } from 'containers/project-manager-page/local-state/hooks';
import { DataSource } from 'common/utils/models/data-source';
import { SourceFileType } from 'common/utils/models/file-type';
import useTooltip from 'common/hooks/useTooltip';

import Accordion from './Accordion';
import SourceLanguageSelect from './SourceLanguageSelect';
import useDeleteFile from '../../text-manager-tab/files/utils/useDeleteFile';
import useReloadFile from '../../text-manager-tab/files/utils/useReloadFile';
import useGetDataSource from '../../text-manager-tab/files/utils/useGetContent';
import { TooltipTitle } from './utils/constants';

import {
  AccordionSummaryStyled, CachedIconStyled,
  CircularSpinnerStyled,
  NestedSourceNameContainerStyled,
} from './styled';
import AccordionDetailsComponent from './AccordionDetailsComponent';

interface AccordionItemProps {
  source: DataSource,
  expandHandle: (event: React.SyntheticEvent, isExpanded: boolean) => void
  expanded: boolean;
}

function AccordionNestedItem({ source, expandHandle, expanded }: AccordionItemProps): JSX.Element {
  const { state: { tabOrientedTo } } = useSetTabOrientationContext();

  const { isDeleting, onDeleteFileHandle } = useDeleteFile();
  const { isSpinning } = useReloadFile({ source });
  const { projectLanguage, isLoading } = useGetDataSource({ source, expanded });
  const { isOpen, onShowTooltip, onHideTooltip } = useTooltip();

  return (
    <Accordion
      id={`panel-nested-id-${source.id}`}
      expanded={expanded && !isLoading}
      onChange={expandHandle}
      disabled={isSpinning || isLoading}
    >
      <AccordionSummaryStyled sx={{ ml: 6 }}>
        <NestedSourceNameContainerStyled>
          {
            (source.type === SourceFileType.Url || source.type === SourceFileType.ScrapedUrl)
           && <VideoLabelIcon fontSize="small" sx={{ ml: 2, mr: 2, color: 'grey.500' }} />
          }
          <Tooltip open={isOpen} title={source.sourceName} arrow>
            <Typography
              noWrap
              onMouseMove={onShowTooltip}
              onMouseOut={onHideTooltip}
              color={source.isFailed ? 'error.main' : 'text.primary'}
            >
              {source.sourceName}
            </Typography>
          </Tooltip>
        </NestedSourceNameContainerStyled>
        {tabOrientedTo === 'project-manager' ? (
          <Grid container sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography component="div">
              <SourceLanguageSelect source={source} />
              {
                (source.detectedLanguage !== projectLanguage
                || (source?.innerStatus !== 'ready_for_extraction' && source.innerStatus === 'created'))
                && (
                  <Tooltip title={TooltipTitle.LanguageNotMatch}>
                    <LanguageIcon
                      color="warning"
                      sx={{
                        ml: source.detectedLanguage !== projectLanguage ? 2 : 4,
                        verticalAlign: 'middle',
                      }}
                    />
                  </Tooltip>
                )
              }
              {
                (source.innerStatus === 'extracting') && (
                  <CachedIconStyled $isUploading={isSpinning} />
                )
              }
            </Typography>
            {isDeleting
              ? <CircularSpinnerStyled size="small" color="primary" />
              : (
                <IconButton
                  sx={{
                    mr: 1.5,
                    pointerEvents: 'all',
                    backgroundColor: isSpinning ? grey[50] : 'inherit',
                  }}
                  size="small"
                  onClick={onDeleteFileHandle(source.id.toString())}
                >
                  <CloseIcon sx={{ color: isSpinning ? grey[900] : 'inherit' }} />
                </IconButton>
              )}
          </Grid>
        ) : (
          <Typography sx={{ ml: -2 }}>
            {source.extractionReport?.tokensCount || source.extractionReport?.uniqueTokenCount
              ? `${source.extractionReport?.tokensCount}
                terms (${source.extractionReport?.uniqueTokenCount} unique)`
              : '-'}
          </Typography>
        )}
      </AccordionSummaryStyled>
      <AccordionDetailsComponent
        expanded={expanded}
        source={source}
        isNested
      />
    </Accordion>

  );
}

export default memo(AccordionNestedItem);
