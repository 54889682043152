import { memo } from 'react';
import { Grid, Typography } from '@mui/material';
import { Control } from 'react-hook-form';

import
ReactHookFormTextFieldOutlined
  from 'common/components/react-hook-forms/ReactHookFormTextFieldOutlined';

import { InputText } from '../utils/constants';
import { getKeyOf, ProjectFieldValues } from './utils/types';

function ProjectInput(
  { control }: {control?: Control<ProjectFieldValues, object>},
): JSX.Element | null {
  if (!control) return null;

  return (
    <Grid item xs={9}>
      <Typography pb={2} variant="subtitle1">
        {InputText.ProjectName}
      </Typography>
      <ReactHookFormTextFieldOutlined
        control={control}
        type="text"
        label={InputText.ProjectName}
        name={getKeyOf<ProjectFieldValues>('projectName')}
      />
    </Grid>
  );
}

export default memo(ProjectInput);
