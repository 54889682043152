export const enum TabText {
  Description = 'Description',
  TextManager = 'Texts',
  Results = 'Results',
}

export const enum ButtonText {
  Edit = 'Edit',
  SentToExtraction = 'Start extraction',
  FileUpload = 'Upload file',
  Add = 'Add',
}

export const LAST_MODIFIED = 'Last modified: ' as const;
