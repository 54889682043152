import { memo } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

import {
  Autocomplete, Grid,
  TextField, Typography,
} from '@mui/material';

import { User } from 'common/utils/models/user';
import { InputText, SelectMessage, SharedWithText } from '../utils/constants';
import { useManageKeycloakUsers } from './utils/hooks';
import { getKeyOf, ProjectFieldValues } from './utils/types';

import { InvitedPeopleWrapperStyled } from './utils/styled';

  interface AccessSettingsProps {
  formProps:UseFormReturn<ProjectFieldValues, object>;
}

function AccessSettings({ formProps }: AccessSettingsProps): JSX.Element | null {
  const {
    changeUsersSelection,
    defaultUsers,
    options,
    selectedUsers,
    InvitedPeople,
    RenderTags,
  } = useManageKeycloakUsers();

  // TODO: Remove then ?
  if (!formProps) return null;

  return (
    <Grid item xs={12}>
      <Typography
        pb={2}
        pt={6}
        variant="subtitle1"
      >
        {InputText.SharedWith}
      </Typography>
      {(!!defaultUsers?.length) && (
        <Grid item xs={10} container columnGap={9}>
          <Grid item xs={7}>
            <Typography variant="body1">{SharedWithText.Name}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body1">{SharedWithText.SendInvite}</Typography>
          </Grid>
        </Grid>
      )}
      <InvitedPeopleWrapperStyled item xs={8}>
        {InvitedPeople}
      </InvitedPeopleWrapperStyled>
      <Grid
        item
        xs={12}
        container
        wrap="nowrap"
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item xs={12}>
          <Controller
            control={formProps.control}
            name={getKeyOf<ProjectFieldValues>('canSee')}
            render={({ field }) => (
              <Autocomplete
                multiple
                onChange={(e, newValue) => changeUsersSelection(field, newValue)}
                options={options?.length ? options : [] as User[]}
                getOptionLabel={(option) => option.email}
                filterSelectedOptions
                noOptionsText={SelectMessage.NoOptionsMatch}
                value={selectedUsers}
                renderTags={(_, getTagProps) => RenderTags(getTagProps)}
                renderInput={(params) => (
                  <TextField
                    {...field}
                    {...params}
                    placeholder="Add users"
                  />
                )}
              />
            )}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default memo(AccessSettings);
