import { useContext } from 'react';
import { CreateEditProjectContext, CreateEditProjectContextType } from './CreateEditTypeContext';
import { RemoveOptionContext, RemoveOptionContextType } from './RemoveOptionContext';
import { UsersContext, UsersContextType } from './UsersContext';

export function useSetCreateOrUpdateProjectContext(): CreateEditProjectContextType {
  const context = useContext(CreateEditProjectContext);

  if (context === undefined) {
    throw new Error('CreateOrUpdateProjectContext must be used within a CreateOrEditProjectPage');
  }

  return context;
}

export function useRemoveOptionContext(): RemoveOptionContextType {
  const context = useContext(RemoveOptionContext);

  if (context === undefined) {
    throw new Error('RemoveOptionContext must be used within a CreateOrEditProjectPage');
  }

  return context;
}

export function useUsersContext(): UsersContextType {
  const context = useContext(UsersContext);

  if (context === undefined) {
    throw new Error('UsersContext must be used within a CreateOrEditProjectPage');
  }

  return context;
}
