import sc from '@mui/styled-engine';

import { Grid } from '@mui/material';

import { PaperStyled } from 'containers/project-manager-page/components/utils/styled';

export const UrlsPaperStyled = sc(PaperStyled)<{ component?: string; }>({
  padding: 0,
  flexDirection: 'column',
  display: 'block',
});

export const InputsWrapperStyled = sc(Grid)(({ theme }) => ({
  padding: theme.spacing(6),
  borderBottom: `1px solid ${theme.palette.divider}`,
}));
