import { useNavigate } from 'react-router-dom';
import {
  useMutation,
  useQueryClient,
} from 'react-query';

import SnackBarUtils from 'common/SnackBar/SnackBarUtils';
import { AppRoute, TabRoute } from 'common/components/routes/utils/constants';
import * as api from './api';
import QueryKey from './QueryKey';
import { MutationResult } from './types';

import { CreateProjectModel } from './models/CreateProject.model';
import { UpdateProjectModel } from './models/UpdateProject.model';

export interface IReturnData {
  error: boolean;
  message: string;
}

export function useCreateProject(): MutationResult<CreateProjectModel> {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation(
    (newProject: CreateProjectModel) => api.createProject(newProject),
    {
      onSettled: async (data) => {
        await queryClient.invalidateQueries(QueryKey.Projects);
        if (data) {
          queryClient.refetchQueries([QueryKey.Projects, data.id]);
          navigate(`${AppRoute.Default}/${data.id}/${TabRoute.TextManager}`);
        }
      },
      onError: (error: Error, project: CreateProjectModel, context: unknown) => {
        const rollback = context;
        if (typeof rollback === 'function') {
          rollback();
          SnackBarUtils.toast({ id: project.id.toString(), message: error.message });
        }

        queryClient.invalidateQueries(QueryKey.Project);
      },
    },
  );
}

export function useUpdateProject(): MutationResult<UpdateProjectModel> {
  const queryClient = useQueryClient();

  return useMutation(
    (input) => api.updateProject({ ...input }),
    {
      onSettled: (data) => {
        if (data?.id) {
          queryClient.invalidateQueries([QueryKey.Project, data.id]);
        }
        queryClient.invalidateQueries(QueryKey.Projects);
      },
      onError: (error: Error, project: UpdateProjectModel) => {
        SnackBarUtils.toast({ id: project.id.toString(), message: error.message });

        queryClient.invalidateQueries([QueryKey.Project, project.id]);
      },
    },
  );
}
