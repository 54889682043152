import { useMutation, useQueryClient } from 'react-query';
import SnackBarUtils from 'common/SnackBar/SnackBarUtils';

import useGetProjectById from 'common/query-hooks/use-get-project-by-id';
import * as api from './api';
import QueryKey from './QueryKey';
import { RequiredIds, ResolveAttentionRequiredResult } from './types';

export function useResolveAttentionRequired(): ResolveAttentionRequiredResult {
  const queryClient = useQueryClient();
  const { projectId } = useGetProjectById();

  return useMutation(
    ({ dataSourceIds, parentIds }: RequiredIds) => api.resolveAttentionRequired(dataSourceIds, parentIds),
    {
      onSettled: async (_, error, ___, rollback) => {
        await queryClient.invalidateQueries([QueryKey.Project, projectId.toString()]);

        if (typeof rollback === 'function' && projectId.toString()) {
          rollback();
          SnackBarUtils.toast({ id: projectId.toString(), message: error?.message });
        }
      },
      onError: async (error: Error, _: RequiredIds, context: unknown) => {
        const rollback = context;
        if (typeof rollback === 'function' && projectId.toString()) {
          rollback();
          SnackBarUtils.toast({ id: projectId.toString(), message: error.message });
        }

        await queryClient.invalidateQueries([QueryKey.Project, projectId.toString()]);
      },
    },
  );
}
