import { SendEmailAction, SendEmailState } from './SendEmailContext';

function sendEmailReducer(
  state: SendEmailState,
  action: SendEmailAction,
): SendEmailState {
  const copy = { ...state };
  if (action.field?.entity) {
    if (action.field.type === 'add') {
      copy.value.push(action.field.entity);
    } if (action.field.type === 'remove') {
      const index = copy.value.findIndex((entity) => entity.email === action.field.entity?.email);
      copy.value.splice(index, 1);

      return copy;
    }
  }
  return copy;
}

export {
  sendEmailReducer,
};
