import { useEffect, useState } from 'react';

import useGetProjectById from 'common/query-hooks/use-get-project-by-id';

import {
  isAttentionRequired, isDone,
  isInExtraction, isNoDataSources, isReadyForExtraction,
} from './helpers';

const useBlockTab = (): {
  blockIfDone: boolean;
  blockIfExtracting: boolean;
  blockIfNoDataSources: boolean;
  blockIfAttentionRequired: boolean;
  blockIfNotReadyForExtraction: boolean;
} => {
  const { data: project } = useGetProjectById();

  const [blockIfDone, setBlockIfDone] = useState(true);
  const [blockIfExtracting, setBlockIfExtracting] = useState(true);
  const [blockIfNoDataSources, setBlockIfNoDataSources] = useState(true);
  const [blockIfAttentionRequired, setBlockIfAttentionRequired] = useState(true);
  const [blockIfNotReadyForExtraction, setBlockIfNotReadyForExtraction] = useState(true);

  useEffect(() => {
    setBlockIfDone(isDone(project.status));
  }, [project]);

  useEffect(() => {
    setBlockIfExtracting(isInExtraction(project.status));
  }, [project]);

  useEffect(() => {
    setBlockIfNoDataSources(isNoDataSources(project?.dataSources?.length));
  }, [project]);

  useEffect(() => {
    setBlockIfAttentionRequired(isAttentionRequired(project.status));
  }, [project]);

  useEffect(() => {
    setBlockIfNotReadyForExtraction(!(
      isReadyForExtraction(project.status) || isDone(project.status)
    ));
  }, [project]);

  return {
    blockIfDone,
    blockIfExtracting,
    blockIfNoDataSources,
    blockIfAttentionRequired,
    blockIfNotReadyForExtraction,
  };
};

export default useBlockTab;
