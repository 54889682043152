import { memo } from 'react';

import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import {
  IconButton, Link, Tooltip, Typography,
} from '@mui/material';
import { blue, grey } from '@mui/material/colors';

import useTooltip from 'common/hooks/useTooltip';
import { CheckedDataSource } from '../../../../local-state/TooMuchFilesContext';

interface CheckedItemProps {
value: CheckedDataSource;
toggle: (value: CheckedDataSource) => () => void
}

function CheckedItem({ value, toggle }: CheckedItemProps): JSX.Element {
  const { isOpen, onShowTooltip, onHideTooltip } = useTooltip();

  const labelId = `checkbox-list-label-${value.id}`;

  const clickHandle = (event:React.MouseEvent<HTMLSpanElement>): void => {
    event.stopPropagation();
  };

  return (
    <ListItem
      key={value.id}
      disablePadding
      sx={{ backgroundColor: value.checked ? blue[50] : 'transparent' }}
    >
      <ListItemButton
        role={undefined}
        onClick={toggle(value)}
        dense
        style={{ display: 'grid', gridTemplateColumns: '40px 40px 1fr 40px' }}
      >
        <ListItemIcon sx={{ width: '12%', justifyContent: 'flex-start' }}>
          <Checkbox
            edge="start"
            checked={value.checked}
            tabIndex={-1}
            disableRipple
            inputProps={{ 'aria-labelledby': labelId }}
          />
        </ListItemIcon>
        <InsertDriveFileOutlinedIcon color="disabled" sx={{ mr: 2 }} />
        <ListItemText
          primary={(
            <Tooltip open={isOpen} title={value.sourceName} arrow>
              <Typography
                onMouseMove={onShowTooltip}
                onMouseOut={onHideTooltip}
                noWrap
              >
                {value.sourceName}
              </Typography>
            </Tooltip>
          )}
          id={labelId}
        />
        <Link
          color={grey[600]}
          component={IconButton}
          href={value.sourceLocation}
          target="_blank"
          download={value.sourceLocation}
          rel="noopener noreferrer"
          onClick={clickHandle}
        >
          <FileDownloadOutlinedIcon />
        </Link>
      </ListItemButton>
    </ListItem>
  );
}

export default memo(CheckedItem);
