import {
  Grid, IconButton,
  Tooltip, Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { grey } from '@mui/material/colors';

import { DataSource } from 'common/utils/models/data-source';
import { SourceFileType } from 'common/utils/models/file-type';
import useTooltip from 'common/hooks/useTooltip';
import {
  useSetTabOrientationContext,
  useModalContext,
  useSetTooMuchFilesContext,
} from 'containers/project-manager-page/local-state/hooks';

import Accordion from './Accordion';
import useDeleteFile from '../../text-manager-tab/files/utils/useDeleteFile';
import useReloadFile from '../../text-manager-tab/files/utils/useReloadFile';
import useGetDataSource from '../../text-manager-tab/files/utils/useGetContent';

import {
  AccordionSummaryStyled,
  CircularSpinnerStyled, SourceNameContainerStyled,
} from './styled';
import AccordionDetailsComponent from './AccordionDetailsComponent';
import LanguageDisplayer from './LanguageDisplayer';

interface AccordionItemProps {
  source: DataSource,
  expandHandle: (event: React.SyntheticEvent, isExpanded: boolean) => void
  expanded: boolean;
  isParentUrlAccordion?: boolean;
}

function AccordionItem({
  source, expandHandle, expanded, isParentUrlAccordion,
}: AccordionItemProps): JSX.Element {
  const { dispatch: modalDispatch } = useModalContext();
  const { state: { tabOrientedTo } } = useSetTabOrientationContext();
  const { dispatch: setTooMuchFilesDispatch } = useSetTooMuchFilesContext();

  const { isDeleting, onDeleteFileHandle } = useDeleteFile();
  const { isSpinning } = useReloadFile({ source });
  const { isLoading } = useGetDataSource({ source, expanded });
  const { isOpen, onShowTooltip, onHideTooltip } = useTooltip();

  const onShowOverloadedFilesModal = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.stopPropagation();
    const onlyTooManyFiles = source.children
      .filter((child) => child.innerStatus === 'too_many_files')
      .map((child) => ({ ...child, checked: false }));

    const onlyTooManyFilesParent = source.children
      .filter((child) => child.innerStatus === 'too_many_files')[0].parent as number;

    setTooMuchFilesDispatch({ setFiles: onlyTooManyFiles, setParents: [onlyTooManyFilesParent] });
    modalDispatch({ open: true, id: 'files' });
  };

  const isSourceTypeUrl = source.type === SourceFileType.Url || source.type === SourceFileType.ScrapedUrl;

  return (
    <Accordion
      expanded={expanded && !isLoading}
      onChange={expandHandle}
      id={source.id.toString()}
      disabled={isSpinning || isLoading}
    >
      <AccordionSummaryStyled
        $isParentUrlAccordion={isParentUrlAccordion && (!isSpinning || !isLoading)}
        expandIcon={
          source.children.some((child) => child.innerStatus === 'too_many_files')
            ? (
              <IconButton onClick={onShowOverloadedFilesModal}>
                <ErrorOutlineIcon color="error" />
              </IconButton>
            )
            : <ArrowForwardIosSharpIcon sx={{ fontSize: '1.5rem' }} />
        }
      >
        <SourceNameContainerStyled $isSourceTypeUrl={isSourceTypeUrl}>
          {
            isSourceTypeUrl && <VideoLabelIcon fontSize="small" sx={{ ml: 2, mr: 2, color: 'grey.500' }} />
          }
          <Tooltip open={isOpen} title={source.sourceName} arrow>
            <Typography
              noWrap
              onMouseMove={onShowTooltip}
              onMouseOut={onHideTooltip}
              color={source.isFailed ? 'error.main' : 'text.primary'}
            >
              {source.sourceName}
            </Typography>
          </Tooltip>
        </SourceNameContainerStyled>
        {tabOrientedTo === 'project-manager' ? (
          <Grid container sx={{ justifyContent: 'space-between', ml: 1.5, alignItems: 'center' }}>
            <LanguageDisplayer source={source} expanded={expanded} isSpinning={isSpinning} />
            {isDeleting
              ? <CircularSpinnerStyled size="small" color="primary" />
              : (
                <IconButton
                  sx={{
                    mr: 1.5,
                    pointerEvents: 'all',
                    backgroundColor: isSpinning ? grey[50] : 'inherit',
                  }}
                  size="small"
                  onClick={onDeleteFileHandle(source.id.toString())}
                >
                  <CloseIcon sx={{ color: isSpinning ? grey[900] : 'inherit' }} />
                </IconButton>
              )}
          </Grid>
        ) : (
          <Typography>
            {source.extractionReport?.tokensCount || source.extractionReport?.uniqueTokenCount
              ? `${source.extractionReport?.tokensCount}
                terms (${source.extractionReport?.uniqueTokenCount} unique)`
              : '-'}
          </Typography>
        )}
      </AccordionSummaryStyled>
      <AccordionDetailsComponent expanded={expanded} source={source} />
    </Accordion>
  );
}

export default AccordionItem;
