import sc from '@mui/styled-engine';

import { Grid } from '@mui/material';

const HEADER_HEIGHT = 60 as const;

export const CreateProjectContainerStyled = sc('form')({
  height: `calc(100% - ${HEADER_HEIGHT + 5}px)`,
});

export const CreateProjectStyled = sc(Grid)(({ theme }) => ({
  width: '100vw',
  margin: 'auto',
  marginTop: theme.spacing(8),
  overflowY: 'auto',
  height: 'inherit',
}));

export const SaveCreatedNewProjectWrapper = sc(Grid)({
  margin: 'auto',
});
