import { createTheme } from '@mui/material';
import { breakpoints } from './maps/breakpoints';
import { spacing } from './maps/spacing';
import { shape } from './maps/shape';
import { transitions } from './maps/transitions';
import { typography } from './maps/typography';
import { components } from './maps/components';

export const theme = createTheme({
  breakpoints,
  shape,
  spacing,
  transitions,
  typography,
  components,
});
