import { AxiosError, AxiosResponse } from 'axios';
import axiosInstance from 'common/interceptors/axios';

import Endpoint from './Endpoint';
import { CreateProjectModel } from './models/CreateProject.model';
import { UpdateProjectModel } from './models/UpdateProject.model';

const axios = axiosInstance;

export const createProject = async (
  newProject: CreateProjectModel,
): Promise<CreateProjectModel> => new Promise<CreateProjectModel>(
  (resolve, reject) => {
    axios.post<CreateProjectModel>(Endpoint.CreateProject, newProject)
      .then((response: AxiosResponse<CreateProjectModel>) => resolve(response.data))
      .catch((error: AxiosError<string>) => reject(error));
  },
);

export const updateProject = async (
  project: UpdateProjectModel,
): Promise<UpdateProjectModel> => new Promise<UpdateProjectModel>(
  (resolve, reject) => {
    axios.patch<UpdateProjectModel>(`${Endpoint.CreateProject}/${project.id}`, { ...project })
      .then((response: AxiosResponse<UpdateProjectModel>) => resolve(response.data))
      .catch((error: AxiosError<string>) => reject(error));
  },
);
