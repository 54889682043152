import React from 'react';

export type CreateEditProjectAction = {
  type: 'create' | 'edit' | 'drop',
};
export type CreateEditProjectState = { value: 'create' | 'edit' | null; };
export type CreateEditProjectDispatch = (action: CreateEditProjectAction) => void;
export type CreateEditProjectContextType = {
  state: CreateEditProjectState;
  dispatch: CreateEditProjectDispatch;
};

export const CreateEditProjectContext = React
  .createContext<CreateEditProjectContextType | undefined>(undefined);
