import { FC, useMemo, useReducer } from 'react';

import { createEditReducer, removeOptionReducer, usersReducer } from './reducers';
import { CreateEditProjectContext } from './CreateEditTypeContext';
import { RemoveOptionContext } from './RemoveOptionContext';
import { UsersContext } from './UsersContext';

const CreateEditProjectContextProvider: FC = function ({ children }): JSX.Element {
  const [createEditState, createEditDispatch] = useReducer(createEditReducer, { value: null });
  const [removeOptionState, removeOptionDispatch] = useReducer(removeOptionReducer, { value: null });
  const [usersState, usersDispatch] = useReducer(usersReducer, {
    selectedUsers: [],
    defaultUsers: [],
  });

  const createEditContextValue = useMemo(() => ({
    state: createEditState,
    dispatch: createEditDispatch,
  }), [createEditState]);

  const removeOptionContextValue = useMemo(() => ({
    state: removeOptionState,
    dispatch: removeOptionDispatch,
  }), [removeOptionState]);

  const usersContextValue = useMemo(() => ({
    state: usersState,
    dispatch: usersDispatch,
  }), [usersState]);

  return (
    <CreateEditProjectContext.Provider value={createEditContextValue}>
      <RemoveOptionContext.Provider value={removeOptionContextValue}>
        <UsersContext.Provider value={usersContextValue}>
          {children}
        </UsersContext.Provider>
      </RemoveOptionContext.Provider>
    </CreateEditProjectContext.Provider>
  );
};

export default CreateEditProjectContextProvider;
