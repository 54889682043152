import { memo } from 'react';

import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import { CardHeader, IconButton } from '@mui/material';
import { grey } from '@mui/material/colors';

import { useModalContext } from 'containers/project-manager-page/local-state/hooks';

import { ModalContentCardStyled } from './styled';

function ModalComponent({ title, children }: {title: string, children: React.ReactNode}): JSX.Element | null {
  const { state: { isOpen: open }, dispatch: modalDispatch } = useModalContext();

  const handleClose = (): void => {
    modalDispatch({ open: false, id: 'closen' });
  };

  if (!children) return null;

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModalContentCardStyled>
          <CardHeader
            title={title}
            titleTypographyProps={{ variant: 'h3' }}
            action={<IconButton onClick={handleClose} aria-label="close"><CloseIcon /></IconButton>}
            sx={{ borderBottom: `1px solid ${grey[300]}` }}
          />
          {children}
        </ModalContentCardStyled>
      </Modal>
    </div>
  );
}

export default memo(ModalComponent);
