export const enum LoginPageText {
  Login = 'Log In',
}

export const enum InputFieldLabel {
  Email = 'Email address',
  Password = 'Password',
}

export const enum InputFieldName {
  Email = 'username',
  Password = 'password',
}
