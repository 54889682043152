import React from 'react';

export type RemoveOptionAction = {
  type: 'remove',
  username: string | null,
};
export type RemoveOptionState = { value: string | null; };
export type RemoveOptionDispatch = (action: RemoveOptionAction) => void;
export type RemoveOptionContextType = {
  state: RemoveOptionState;
  dispatch: RemoveOptionDispatch;
};

export const RemoveOptionContext = React
  .createContext<RemoveOptionContextType | undefined>(undefined);
