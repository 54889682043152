import { memo } from 'react';

import { Tooltip, Typography } from '@mui/material';

import LanguageIcon from '@mui/icons-material/Language';

import { DataSource } from 'common/utils/models/data-source';

import SourceLanguageSelect from './SourceLanguageSelect';
import { TooltipTitle } from './utils/constants';
import useGetDataSource from '../../text-manager-tab/files/utils/useGetContent';

import { CachedIconStyled } from './styled';

interface LanguageDisplayerProps {
source: DataSource;
 expanded: boolean;
 isSpinning: boolean
}

function LanguageDisplayer({ source, expanded, isSpinning }: LanguageDisplayerProps): JSX.Element {
  const { projectLanguage, isLoading } = useGetDataSource({ source, expanded });

  return (
    <Typography component="div">
      <SourceLanguageSelect source={source} />
      {
        (source.detectedLanguage !== projectLanguage && !isSpinning)
                  && (
                    <Tooltip title={TooltipTitle.LanguageNotMatch}>
                      <LanguageIcon
                        color="warning"
                        sx={{
                          ml: source.detectedLanguage !== projectLanguage ? 2 : 4,
                          verticalAlign: 'middle',
                        }}
                      />
                    </Tooltip>
                  )
      }
      {
        (source.innerStatus !== 'ready_for_extraction'
                 && source.innerStatus !== 'extracted')
                  && (<CachedIconStyled $isUploading={isSpinning || isLoading} />)
      }
    </Typography>
  );
}

export default memo(LanguageDisplayer);
