import { useCallback, useRef } from 'react';
import sortBy from 'lodash/sortBy';

import { TermsColor } from 'containers/project-manager-page/components/results-tab/utils/constants';
import { ExtractionType } from 'containers/project-manager-page/components/utils/types';
import { SourceToken } from 'common/utils/models/data-source';
import { HighlightResult } from './types';

const useSetHighlight = (
  { tokens, textContent }: { tokens: SourceToken[]; textContent: string; },
): { result: HighlightResult; } => {
  const result = useRef<HighlightResult | null>(null);

  const setBackground = useCallback((type: ExtractionType) => {
    const colored = {
      ner: '#FFCC80',
      abbr: '#A5D6A7',
      st: '#CE93D8',
    } as { [key in ExtractionType]: `${TermsColor}` };

    return colored[type];
  }, []);

  if (tokens.length) {
    result.current = sortBy(tokens, 'beginOffset').reduce<HighlightResult>((acc, token) => {
      if (acc.lastIndex >= token.beginOffset) { // if token overlap - ignore overlapping one
        return acc;
      }
      acc.texts.push({
        text: textContent.substring(acc.lastIndex, token.beginOffset),
        color: 'transparent',
      });
      acc.texts.push({
        text: textContent.substring(token.beginOffset, token.endOffset),
        color: setBackground(token.type),
      });
      acc.lastIndex = token.endOffset;
      return acc;
    }, { texts: [], lastIndex: 0 });
    result.current.texts.push({
      text: textContent.substring(result.current.lastIndex),
      color: 'transparent',
    });
  } else {
    result.current = { texts: [{ color: 'transparent', text: textContent }], lastIndex: 0 };
  }

  return {
    result: result.current,
  };
};

export default useSetHighlight;
