import { memo } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Button, Container } from '@mui/material';

import { DividerStyled } from './utils/styled';
import { ButtonText } from '../utils/constants';

function SaveCreatedNewProject({ saveButtonText }: {saveButtonText: `${ButtonText}` }): JSX.Element {
  const navigate = useNavigate();

  const onGoBackHandle = (): void => {
    navigate(-1);
  };

  return (
    <Container fixed>
      <DividerStyled flexItem />
      <Box
        display="flex"
        justifyContent="flex-end"
      >
        <Button
          variant="outlined"
          sx={{ mr: 6 }}
          onClick={onGoBackHandle}
        >
          {ButtonText.Cancel}
        </Button>
        <Button
          variant="contained"
          type="submit"
        >
          {saveButtonText}
        </Button>
      </Box>
    </Container>
  );
}

export default memo(SaveCreatedNewProject);
