import { Grid } from '@mui/material';

import { TabRoute } from 'common/components/routes/utils/constants';

import Files from '../text-manager-tab/files';
import Texts from '../text-manager-tab/text';
import URLs from '../text-manager-tab/urls';
import ExtractedTerms from './ExtractedTerms';
import InfoSection from './InfoSection';

import { ResultsTabPanelStyled, ResultsTabWrapperStyled } from './utils/styled';

function ResultsTab(): JSX.Element {
  return (
    <ResultsTabPanelStyled value={TabRoute.Results}>
      <Grid container columnGap={4} justifyContent="space-between" wrap="nowrap">
        <ResultsTabWrapperStyled item xs={8}>
          <InfoSection />
          <Grid mt={6} item>
            <Files />
          </Grid>
          <Grid mt={6} item>
            <URLs />
          </Grid>
          <Grid mt={6} item>
            <Texts />
          </Grid>
        </ResultsTabWrapperStyled>
        <ResultsTabWrapperStyled item xs={4}>
          <ExtractedTerms />
        </ResultsTabWrapperStyled>
      </Grid>
    </ResultsTabPanelStyled>
  );
}

export default ResultsTab;
