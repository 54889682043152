import { UseFormReturn } from 'react-hook-form';

import { Grid, Typography } from '@mui/material';

import ReactHookFormDatePicker from 'common/components/react-hook-forms/ReactHookFormDatePicker';

import { useCallback, useEffect, useState } from 'react';
import { InputText } from '../utils/constants';
import { getKeyOf, ProjectFieldValues } from './utils/types';

interface DatePickersProps {
  formProps?: UseFormReturn<ProjectFieldValues, object>;
}

function DatePickers({ formProps }: DatePickersProps): JSX.Element | null {
  const [endMinDate, setEndMinDate] = useState<undefined | Date>(undefined);

  const changeStartDateHandle = useCallback((date: unknown): void => {
    setEndMinDate(date as Date);
    const endDate = formProps?.getValues('endDate') || '';
    if (Date.parse(date as string) > Date.parse(endDate)) {
      formProps?.setValue('endDate', date as string);
    }
    formProps?.setValue('startDate', date as string);
  }, [formProps]);
  const changeEndDateHandle = useCallback((date: unknown): void => {
    formProps?.setValue('endDate', date as string);
  }, [formProps]);

  const [currentStartDate, currentEndDate] = (
    formProps?.getValues(['startDate', 'endDate']) || [undefined, undefined]
  );
  const currentStartDateString = currentStartDate?.toString();
  const currentEndDateString = currentEndDate?.toString();
  useEffect((): void => {
    if (!formProps?.control) return;
    const rawStartDate = formProps?.getValues(['startDate'])[0] || (new Date()).toString();
    const startDate: Date = new Date(Date.parse(rawStartDate));

    setEndMinDate(startDate);
  }, [currentStartDateString, currentEndDateString, formProps]);

  if (!formProps?.control || !endMinDate) return null;

  return (
    <>
      <Grid item container direction="column" xs={6}>
        <Typography
          pb={2}
          pt={6}
          variant="subtitle1"
        >
          {InputText.StartDate}
        </Typography>
        <ReactHookFormDatePicker
          control={formProps.control}
          name={getKeyOf<ProjectFieldValues>('startDate')}
          onChange={changeStartDateHandle}
        />
      </Grid>
      <Grid item container direction="column" xs={6}>
        <Typography
          pb={2}
          pt={6}
          variant="subtitle1"
        >
          {InputText.EndDate}
        </Typography>
        <ReactHookFormDatePicker
          control={formProps.control}
          name={getKeyOf<ProjectFieldValues>('endDate')}
          onChange={changeEndDateHandle}
          minDate={endMinDate}
        />
      </Grid>
    </>
  );
}

export default DatePickers;
