import { User } from 'common/utils/models/user';
import React from 'react';

export type UsersAction = {
  type: 'set-existed-users' | 'set-selected-users';
  users: User[],
};
export type UsersState = {
  defaultUsers: User[];
  selectedUsers: User[];
};
export type UsersDispatch = (action: UsersAction) => void;
export type UsersContextType = {
  state: UsersState;
  dispatch: UsersDispatch;
};

export const UsersContext = React
  .createContext<UsersContextType | undefined>(undefined);
