import { memo } from 'react';

import { AccordionProps } from '@mui/material/Accordion';
import { AccordionStyled } from './styled';

function Accordion(props: AccordionProps): JSX.Element {
  return <AccordionStyled disableGutters elevation={0} square {...props} />;
}

export default memo(Accordion);
