import sc from '@mui/styled-engine';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

export const FilesContainerStyled = sc(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row-reverse',
  alignItems: 'center',
  padding: theme.spacing(4, 8),
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

export const AccordionsPaperStyled = sc(Paper)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
}));
