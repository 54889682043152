import { blue } from '@mui/material/colors';
import { defaultTheme } from '../defaultTheme';

const MuiButton = {
  styleOverrides: {
    contained: {
      backgroundColor: blue[500],
      padding: defaultTheme.spacing(2.5, 11),
      ':hover': {
        backgroundColor: blue[700],
      },
    },
    outlined: {
      fontWeight: 'bold',
      borderWidth: 2,
      ':hover': {
        borderWidth: 2,
      },
    },
    root: {
      padding: defaultTheme.spacing(1, 11),
      boxShadow: 'none',
      ':hover': {
        boxShadow: 'none',
      },
    },
  },
};
export default MuiButton;
