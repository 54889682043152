import { useEffect, useRef } from 'react';

import useGetProjectById from 'common/query-hooks/use-get-project-by-id';
import { DataSource } from 'common/utils/models/data-source';
import { useGetSourceBy } from '../../lib/query-hooks';

interface ReloadFile {
  isSpinning: boolean;
}

const useReloadFile = ({ source }: { source: DataSource; }): ReloadFile => {
  const { projectId, data: project, refetchProject } = useGetProjectById();
  const { data } = useGetSourceBy({ id: source.id.toString(), projectId });

  const spinningRef = useRef<boolean>(false);

  useEffect(() => {
    if (
      source?.innerStatus !== 'ready_for_extraction'
      && source?.innerStatus !== 'too_many_files'
      && source?.innerStatus !== 'extracted'
      && source.id) {
      spinningRef.current = true;
    }
  }, [source.id, source?.innerStatus]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (
        source?.innerStatus !== 'ready_for_extraction'
        && data?.innerStatus !== 'too_many_files'
        && source?.innerStatus !== 'extracted'
        && source.id) {
        refetchProject({ cancelRefetch: project.status === 'Done' });
      }
    }, 4000);

    return () => clearInterval(interval);
  }, [data?.innerStatus, project.status, refetchProject, source.id, source?.innerStatus]);

  useEffect(() => {
    if (
      source?.innerStatus === 'ready_for_extraction'
      || data?.innerStatus === 'ready_for_extraction'
      || source?.innerStatus === 'too_many_files'
      || data?.innerStatus === 'too_many_files'
      || source?.innerStatus === 'extracted'
    ) {
      spinningRef.current = false;
    }
  }, [data?.innerStatus, source?.innerStatus]);

  return {
    isSpinning: spinningRef.current,
  };
};

export default useReloadFile;
