import { defaultTheme } from '../defaultTheme';

const MuiOutlinedInput = {
  styleOverrides: {
    root: {
      minHeight: 36,
      padding: defaultTheme.spacing(0, 2),
    },
    input: {
      height: '100%',
      padding: defaultTheme.spacing(2, 2),
    },
    multiline: {
      padding: defaultTheme.spacing(4, 3),

    },
  },
};
export default MuiOutlinedInput;
