import { useNavigate } from 'react-router-dom';

import { Divider, Grid, Typography } from '@mui/material';

import { EditButtonStyled } from 'containers/project-manager-page/utils/styled';
import { ButtonText, LAST_MODIFIED } from 'containers/project-manager-page/utils/constants';
import { TabRoute } from 'common/components/routes/utils/constants';
import useGetProjectById from 'common/query-hooks/use-get-project-by-id';
import dayjs from 'dayjs';

function InformationEditButton(): JSX.Element {
  const navigate = useNavigate();
  const { data } = useGetProjectById();

  const updateAtNormalized = dayjs(data?.updateDate).format('MM.DD.YYYY');

  const onEditInformationHandle = (): void => {
    navigate({ pathname: TabRoute.EditDescription });
  };

  return (
    <>
      <Divider sx={{ mb: 8 }} />
      <Grid
        item
        container
        xs={12}
        justifyContent="space-between"
        sx={{ pl: 2, pr: 2 }}
      >
        <Typography variant="body2">
          {`${LAST_MODIFIED} ${updateAtNormalized}`}
        </Typography>
        <EditButtonStyled variant="contained" color="info" onClick={onEditInformationHandle}>
          {ButtonText.Edit}
        </EditButtonStyled>
      </Grid>
    </>
  );
}

export default InformationEditButton;
