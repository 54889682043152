import {
  useCallback, useEffect, useMemo,
} from 'react';
import { ControllerRenderProps } from 'react-hook-form';
import uniq from 'lodash/uniq';

import { User } from 'common/utils/models/user';

import { AutocompleteRenderGetTagProps, Chip } from '@mui/material';
import InvitedPeopleItem from 'common/components/invited-people-item';
import { useUsersContext } from 'containers/create-project-page/local-state/hooks';
import useGetProjectById from 'common/query-hooks/use-get-project-by-id';
import { useLocation } from 'react-router-dom';
import { useGetMyKeyCloakUserList } from '../../lib/query-hooks';
import { ProjectFieldValues } from './types';

interface ManageKeycloakUsers {
  selectedUsers: User[];
  defaultUsers: User[];
  options: User[];
  InvitedPeople: JSX.Element[];
  RenderTags: (getTagProps: AutocompleteRenderGetTagProps) => JSX.Element[]
  changeUsersSelection: (
    field: ControllerRenderProps<ProjectFieldValues, keyof ProjectFieldValues>,
    options: User[],
  ) => void;
}

export const useManageKeycloakUsers = (): ManageKeycloakUsers => {
  const { dispatch: usersDispatch, state: { defaultUsers, selectedUsers } } = useUsersContext();

  const { pathname } = useLocation();
  const { data: users } = useGetMyKeyCloakUserList();
  const { data } = useGetProjectById();

  useEffect(() => {
    if (!defaultUsers?.length && !pathname.includes('create')) {
      usersDispatch({ type: 'set-existed-users', users: data?.canSee || [] });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, usersDispatch]);

  const handleChange = useCallback((
    { onChange }: ControllerRenderProps<ProjectFieldValues, keyof ProjectFieldValues>,
    candidates: User[],
  ): void => {
    onChange(candidates);
    usersDispatch({
      type: 'set-selected-users',
      users: candidates,
    });

    usersDispatch({
      type: 'set-existed-users',
      users: uniq([...candidates, ...defaultUsers]),
    });
  }, [defaultUsers, usersDispatch]);

  const options = useMemo(() => users?.filter((user) => !defaultUsers
    ?.find((remove) => (remove.email === user.email))), [defaultUsers, users]);

  const InvitedPeople = defaultUsers.map((option: User) => {
    const onDeleteHandle = (): void => {
      usersDispatch({
        type: 'set-existed-users',
        users: defaultUsers.filter((entry) => entry.email !== option.email),
      });
      usersDispatch({
        type: 'set-selected-users',
        users: selectedUsers.filter((user) => user.email !== option.email),
      });
    };

    return (
      <InvitedPeopleItem
        key={option.username}
        person={option}
        removeOption={onDeleteHandle}
        isInformationPage
      />
    );
  });

  const RenderTags = (getTagProps: AutocompleteRenderGetTagProps): JSX.Element[] => selectedUsers
    ?.map((option, index) => {
      const deleteFromRenderTagsHandle = (): void => {
        usersDispatch({
          type: 'set-selected-users',
          users: selectedUsers.filter((v) => v.email !== option.email),
        });
        usersDispatch({
          type: 'set-existed-users',
          users: defaultUsers.filter((user) => user.email !== option.email),
        });
      };

      return (
        <Chip
          {...getTagProps({ index })}
          key={option.username}
          label={option.username}
          onDelete={deleteFromRenderTagsHandle}
        />
      );
    });

  return {
    defaultUsers,
    selectedUsers,
    options: options || [],
    changeUsersSelection: handleChange,
    InvitedPeople,
    RenderTags,
  };
};
