import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Button, CardContent } from '@mui/material';

import { ButtonText } from 'common/components/create-edit-page/utils/constants';
import ModalComponent from 'common/components/modal-component';
import {
  useModalContext,
  useSetTooMuchFilesContext,
} from 'containers/project-manager-page/local-state/hooks';

import { CARD_HEADER_TITLE, MODAL_CONTENT_SUBHEADER } from './FilesOverloadModal/utils/constants';
import CardContentList from './FilesOverloadModal/CardContentList';
import { useResolveAttentionRequired } from './lib/query-hooks';

function FilesModal(): JSX.Element {
  const { dispatch: modalDispatch } = useModalContext();
  const { dispatch: setTooMuchFilesDispatch, state: { files, parentIds } } = useSetTooMuchFilesContext();

  const { mutateAsync } = useResolveAttentionRequired();

  const handleClose = (): void => {
    modalDispatch({ open: false, id: 'closen' });
    setTooMuchFilesDispatch({ setFiles: [], setParents: [] });
  };

  const sendToResolveTooManyFiles = async (): Promise<void> => {
    const dataSourceIds = files
      .filter((file) => file.checked)
      .map((file) => file.id);

    const response = await mutateAsync({ dataSourceIds, parentIds });
    if (response.detail === 'ok') {
      handleClose();
    }
  };

  return (
    <ModalComponent title={CARD_HEADER_TITLE}>
      <CardContent>
        <Typography sx={{ mt: 2 }}>
          {MODAL_CONTENT_SUBHEADER}
        </Typography>
        <CardContentList />
      </CardContent>
      <Box p={4} pt={0} display="flex" justifyContent="flex-end">
        <Button
          onClick={handleClose}
          variant="outlined"
          sx={{ pl: 4, pr: 4, mr: 4 }}
        >
          {ButtonText.Cancel}
        </Button>
        <Button
          onClick={sendToResolveTooManyFiles}
          variant="contained"
          sx={{ pl: 4, pr: 4 }}
        >
          {ButtonText.Ok}
        </Button>
      </Box>
    </ModalComponent>
  );
}

export default FilesModal;
