import { FC, useMemo, useReducer } from 'react';

import { TabRoute } from 'common/components/routes/utils/constants';

import {
  setOpenModalReducer, setOrientationReducer, setTabValueReducer, setTooMuchFilesReducer,
} from './reducers';
import { TabOrientationContext } from './TabOrientationContext';
import { TooMuchFilesContext } from './TooMuchFilesContext';
import { ModalContext } from './ModalContext';
import { TabsContext } from './TabsContext';

const ProjectManagerContextProvider: FC = function ({ children }): JSX.Element {
  const [orientationState, orientationDispatch] = useReducer(setOrientationReducer, { tabOrientedTo: '' });
  const [modalState, modalDispatch] = useReducer(setOpenModalReducer, { isOpen: false, id: 'closen' });
  const [filesState, filesDispatch] = useReducer(setTooMuchFilesReducer, { files: [], parentIds: [] });
  const [tabState, tabDispatch] = useReducer(setTabValueReducer, { tabValue: TabRoute.TextManager });

  const tabOrientationContextValue = useMemo(() => ({
    state: orientationState,
    dispatch: orientationDispatch,
  }), [orientationState]);

  const modalContextValue = useMemo(() => ({
    state: modalState,
    dispatch: modalDispatch,
  }), [modalState]);

  const filesContextValue = useMemo(() => ({
    state: filesState,
    dispatch: filesDispatch,
  }), [filesState]);

  const tabContextValue = useMemo(() => ({
    state: tabState,
    dispatch: tabDispatch,
  }), [tabState]);

  return (
    <TooMuchFilesContext.Provider value={filesContextValue}>
      <ModalContext.Provider value={modalContextValue}>
        <TabOrientationContext.Provider value={tabOrientationContextValue}>
          <TabsContext.Provider value={tabContextValue}>
            {children}
          </TabsContext.Provider>
        </TabOrientationContext.Provider>
      </ModalContext.Provider>
    </TooMuchFilesContext.Provider>
  );
};

export default ProjectManagerContextProvider;
