import {
  useQuery, useQueryClient, UseQueryResult,
} from 'react-query';

import { relogin } from 'containers/authentication/lib/api';
import * as api from './api';
import QueryKey from './QueryKey';
import { GetReportParamsModel, ReportResult } from './types';

export function useGetReport(params: GetReportParamsModel): UseQueryResult<ReportResult, Error> {
  const queryClient = useQueryClient();

  return useQuery(
    [QueryKey.Report],
    () => api.getReport(params),
    {
      refetchOnWindowFocus: false,
      enabled: false,
      onSettled: () => {
        queryClient.invalidateQueries([QueryKey.Report]);
      },
      initialData: () => queryClient.getQueryData([QueryKey.Report]),
      onError: async () => {
        await relogin();
      },
    },
  );
}
