import { Box, Grid, Typography } from '@mui/material';

import { INFO, SQUARES_COLORS } from './utils/constants';

import { ColoredSquareStyled } from './utils/styled';

function InfoSection(): JSX.Element {
  return (
    <Grid item>
      <Typography variant="body1">{INFO}</Typography>
      {SQUARES_COLORS.map(({ color, value }) => (
        <Box key={color} display="inline-flex" pr={8} pt={6} alignItems="center">
          <ColoredSquareStyled sx={{ backgroundColor: color }} />
          <Typography variant="body1">{value}</Typography>
        </Box>
      ))}
    </Grid>
  );
}

export default InfoSection;
