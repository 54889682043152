import { useNavigate } from 'react-router-dom';

// import SearchIcon from '@mui/icons-material/Search';

import { AppRoute } from 'common/components/routes/utils/constants';
import { useSetCreateOrUpdateProjectContext } from 'containers/create-project-page/local-state/hooks';

import { TEXT } from '../utils/constants';

import {
  ButtonStyled,
  // InputSearchStyled,
  // SearchIconWrapperStyled, SearchStyled,
} from '../utils/styled';

function ProjectsAppBarSet(): JSX.Element {
  const navigate = useNavigate();
  const { dispatch } = useSetCreateOrUpdateProjectContext();

  const onNavigateToCreateProjectHandle = (): void => {
    dispatch({ type: 'drop' });
    navigate(AppRoute.CreateProject, { state: { from: { pathname: AppRoute.CreateProject } } });
  };

  return (
    <>
      {/* Commented and would be implemented in future improvements */}
      {/* <SearchStyled>
        <SearchIconWrapperStyled>
          <SearchIcon color="info" />
        </SearchIconWrapperStyled>
        <InputSearchStyled
          placeholder={TEXT.SARCH}
          inputProps={{ 'aria-label': 'search' }}
        />
      </SearchStyled> */}
      <ButtonStyled
        variant="contained"
        onClick={onNavigateToCreateProjectHandle}
      >
        {TEXT.BUTTON.CREATE_NEW}
      </ButtonStyled>
    </>
  );
}

export default ProjectsAppBarSet;
