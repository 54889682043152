import React from 'react';

export type TabOrientationAction = { setOrientationTo: 'project-manager' | 'results'; };
export type TabOrientationState = { tabOrientedTo: 'project-manager' | 'results' | ''; };
export type TabOrientationDispatch = (action: TabOrientationAction) => void;
export type TabOrientationContextType = {
  state: TabOrientationState;
  dispatch: TabOrientationDispatch;
};

export const TabOrientationContext = React.createContext<TabOrientationContextType | undefined>(undefined);
