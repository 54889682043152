import { memo } from 'react';
import { UseFormReturn } from 'react-hook-form';

import AccessSettings from './components/AccessSettings';
import DatePickers from './components/DatePickers';
import Description from './components/Description';
import LanguageSelect from './components/LanguageSelect';
import ProjectInput from './components/ProjectInput';
import { ProjectFieldValues } from './components/utils/types';

interface ProjectContentProps {
  formProps: UseFormReturn<ProjectFieldValues, object>,
}

function ProjectContent({ formProps }: ProjectContentProps): JSX.Element {
  return (
    <>
      <ProjectInput control={formProps.control} />
      <LanguageSelect formProps={formProps} />
      <Description control={formProps.control} />
      <DatePickers formProps={formProps} />
      <AccessSettings formProps={formProps} />
    </>
  );
}

export default memo(ProjectContent);
