import { memo } from 'react';

import SaveCreatedNewProject from './components/SaveCreatedNewProject';
import ProjectContent from './ProjectContent';
import { ButtonText } from './utils/constants';

import {
  CreateProjectContainerStyled,
  CreateProjectStyled,
  SaveCreatedNewProjectWrapper,
} from './utils/styled';
import { useCreateUpdateProject } from './utils/hooks';

function CreateEditInformationPage(): JSX.Element {
  const { onCreateOrUpdateProjectHandle, formProps, operationType } = useCreateUpdateProject();

  return (
    <CreateProjectContainerStyled
      onSubmit={formProps.handleSubmit(onCreateOrUpdateProjectHandle)}
    >
      <CreateProjectStyled
        item
        container
        xs={11}
        md={10}
        lg={6}
        columnSpacing={4}
        alignItems="start"
        justifyContent="start"
        pr={3}
      >
        <ProjectContent formProps={formProps} />
      </CreateProjectStyled>
      <SaveCreatedNewProjectWrapper
        item
        container
        xs={6}
        columnSpacing={4}
      >
        <SaveCreatedNewProject
          saveButtonText={
            operationType === 'edit'
              ? ButtonText.Save
              : ButtonText.Create
          }
        />
      </SaveCreatedNewProjectWrapper>
    </CreateProjectContainerStyled>
  );
}

export default memo(CreateEditInformationPage);
