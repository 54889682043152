import { useLocation } from 'react-router-dom';

import useGetProjectById from 'common/query-hooks/use-get-project-by-id';
import { Status } from 'containers/project-list-page/utils/enums';

import { AppBarContentMap } from './utils/types';
import ProjectsAppBarSet from './components/ProjectsAppBarSet';
import CreateProjectAppBarSet from './components/CreateProjectAppBarSet';
import ProjectAppBarSet from './components/ProjectAppBarSet';

import { Routes } from '../routes/utils/constants';

function ContentToRender(): JSX.Element {
  const location = useLocation();
  const { data, projectId } = useGetProjectById();
  const renderContentAccording = {
    '/projects': <ProjectsAppBarSet />,
    '/projects/create': <CreateProjectAppBarSet />,
    '/projects/:id': <ProjectAppBarSet withStatus={false} />,
    '/projects/:id/description': <ProjectAppBarSet withStatus />,
    '/projects/:id/description/edit': <ProjectAppBarSet withStatus />,
    '/projects/:id/input-manager': <ProjectAppBarSet withStatus />,
    '/projects/:id/results': <ProjectAppBarSet withStatus />,
  } as AppBarContentMap;

  if (projectId && location.pathname.includes(projectId.toString())) {
    return (
      <ProjectAppBarSet
        withStatus
        projectName={data?.projectName}
        status={data?.status as `${Status}`}
      />
    );
  }

  return renderContentAccording[location.pathname as `${Routes}`];
}

export default ContentToRender;
