import MuiButton from './MuiButton';
import MuiOutlinedInput from './MuiOutlinedInput';
import MuiChip from './MuiChip';
import MuiSelect from './MuiSelect';
import MuiCalendarPicker from './MuiCalendarPicker';
import MuiMenu from './MuiMenu';
import MuiSlider from './MuiSlider';

export const components = {
  MuiButton,
  MuiOutlinedInput,
  MuiChip,
  MuiSelect,
  MuiCalendarPicker,
  MuiMenu,
  MuiSlider,
};
