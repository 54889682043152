import { useSnackbar, VariantType, WithSnackbarProps } from 'notistack';
import { SnackContent } from './types';

interface IProps {
  setUseSnackbarRef: (showSnackbar: WithSnackbarProps) => void;
}

function InnerSnackbarUtilsConfigurator({ setUseSnackbarRef }: IProps): null {
  setUseSnackbarRef(useSnackbar());
  return null;
}

let useSnackbarRef: WithSnackbarProps;
const setUseSnackbarRef = (useSnackbarRefProp: WithSnackbarProps): void => {
  useSnackbarRef = useSnackbarRefProp;
};

export function SnackbarUtilsConfigurator():JSX.Element {
  return (
    <InnerSnackbarUtilsConfigurator setUseSnackbarRef={setUseSnackbarRef} />
  );
}

export default {
  toast({
    id, projectName, message, actionType = 'default',
  }: Partial<SnackContent>, variant: VariantType = 'default'): void {
    useSnackbarRef.enqueueSnackbar({
      id, projectName, message, actionType,
    }, { variant });
  },
};
