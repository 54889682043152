import { CreateDataSourceModel } from './models/CreateDataSource.model';
import { RequestFormData } from './types';

const prepareFileFormData = (dataSource: Required<CreateDataSourceModel>): FormData => {
  const formData = new FormData();

  formData.append(RequestFormData.File, dataSource.file);
  formData.append(RequestFormData.ProjectId, dataSource.id.toString());
  formData.append(RequestFormData.SourceName, dataSource.sourceName);
  formData.append(RequestFormData.Type, dataSource.type);

  return formData;
};

const prepareTextFormData = (dataSource: Required<CreateDataSourceModel>): FormData => {
  const formData = new FormData();

  formData.append(RequestFormData.Text, dataSource.text);
  formData.append(RequestFormData.ProjectId, dataSource.id.toString());
  formData.append(RequestFormData.SourceName, dataSource.sourceName);
  formData.append(RequestFormData.Type, dataSource.type);

  return formData;
};

export const prepareUrlFormData = (dataSource: Required<CreateDataSourceModel>): FormData => {
  const formData = new FormData();

  formData.append(RequestFormData.SourceLocation, dataSource.sourceLocation);
  formData.append(RequestFormData.ProjectId, dataSource.id.toString());
  formData.append(RequestFormData.SourceName, dataSource.sourceName);
  formData.append(RequestFormData.Type, dataSource.type);

  return formData;
};

export const prepareLanguageFormData = (dataSource: Required<CreateDataSourceModel>): FormData => {
  const formData = new FormData();

  formData.append(RequestFormData.DetectedLanguage, dataSource.detectedLanguage);
  formData.append(RequestFormData.ProjectId, dataSource.id.toString());
  formData.append(RequestFormData.SourceName, dataSource.sourceName);
  formData.append(RequestFormData.Type, dataSource.type);

  return formData;
};

export {
  prepareFileFormData,
  prepareTextFormData,
};
