import sc from '@mui/styled-engine';

import { Avatar, Divider, Grid } from '@mui/material';

export const DividerStyled = sc(Divider)(({ theme }) => ({
  width: '100%',
  paddingBottom: theme.spacing(6),
  marginBottom: theme.spacing(4),
}));

export const InvitedPeopleWrapperStyled = sc(Grid)(({ theme }) => ({
  maxHeight: '16vh',
  overflow: 'auto',
  marginBottom: theme.spacing(2),
}));

export const AvatarStyled = sc(Avatar)({
  width: 48,
  height: 48,
});
