import React from 'react';

export type ModalAction = { open: boolean; id: 'extraction' | 'files' | 'closen'; };
export type ModalState = { isOpen: boolean, id: 'extraction' | 'files' | 'closen'; };
export type ModalDispatch = (action: ModalAction) => void;
export type ModalContextType = {
  state: ModalState;
  dispatch: ModalDispatch;
};

export const ModalContext = React.createContext<ModalContextType | undefined>(undefined);
