import { useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';

import useGetProjectById from 'common/query-hooks/use-get-project-by-id';

import { modifyExtractionTypes } from '../../text-manager-tab/utils/helpers';
import { ExtractionType } from '../../utils/types';
import { useGetReport } from '../lib/query-hooks';
import { GetReportParamsModel, ReportResult } from '../lib/types';
import { DEFAULT_TERMS_NUMBER, TermsColor } from './constants';

interface SetExtractedTermsResult {
  report: GetReportParamsModel;
  tokens: ReportResult;
  lastExtraction: string;
  isFetching: boolean;
  isCopiedToClipboard: boolean;
  changeNumberOfTermsHandle: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  handleChangeCheckbox: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  copyToClipboardHandle: VoidFunction;
  setBackground: (type: ExtractionType) => `${TermsColor}`;
}

export const useSetExtractedTerms = (): SetExtractedTermsResult => {
  const { projectId, data: { lastExtracted, status } } = useGetProjectById();

  const [isCopied, setCopied] = useState(false);
  const [report, setReport] = useState<GetReportParamsModel>({
    project: projectId,
    limit: DEFAULT_TERMS_NUMBER,
    types: ['ner', 'abbr', 'st'],
  });

  const updateAtNormalized = dayjs(lastExtracted).format('MM.DD.YYYY');

  const setBackground = useCallback((type: ExtractionType) => {
    const colored = {
      ner: '#FFCC80',
      abbr: '#A5D6A7',
      st: '#CE93D8',
    } as { [key in ExtractionType]: `${TermsColor}` };

    return colored[type];
  }, []);

  const { data: tokens, refetch, isFetching } = useGetReport(report);

  const changeNumberOfTermsHandle = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ): void => {
    setReport((prev) => ({ ...prev, limit: +event.target.value }));
  };

  const handleChangeCheckbox = useCallback((
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ): void => {
    setReport((prev) => ({
      ...prev,
      types: modifyExtractionTypes(prev.types, event.target.name, checked),
    }));
  }, []);

  const copyToClipboardHandle = useCallback((): void => {
    setCopied(true);
    if (tokens) {
      navigator.clipboard.writeText(tokens.map(({ token }) => token).join(' '));
    }
  }, [tokens]);

  useEffect(() => {
    if (status === 'Done') refetch();
    setCopied(false);
  }, [tokens, refetch, report, status]);

  return {
    report,
    tokens: tokens ?? [],
    lastExtraction: `Last extracted: ${updateAtNormalized}`,
    isFetching,
    isCopiedToClipboard: isCopied,
    changeNumberOfTermsHandle,
    handleChangeCheckbox,
    copyToClipboardHandle,
    setBackground,
  };
};
