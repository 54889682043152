export const INFO = 'You can change text sources on the Texts tab.' as const;
export const DEFAULT_TERMS_NUMBER = 10 as const;

export enum TermsColor {
  Orange = '#FFCC80',
  Green = '#A5D6A7',
  Violet = '#CE93D8'
}

export const SQUARES_COLORS = [
  {
    color: TermsColor.Orange,
    value: 'Named entity',
  }, {
    color: TermsColor.Green,
    value: 'Abbreviation',
  }, {
    color: TermsColor.Violet,
    value: 'Specific term',
  },
] as const;

export const enum ExtractedTermsText {
  Terms = 'Extracted terms',
  Mark = 'Sorted by frequency from all texts',
  NumberOfTerms = 'Number of terms',
  CopyToClipboard = 'Copy to clipboard',
  Copied = 'Copied',
}

export const enum ControlLabel {
  Entity = 'Entity',
  Abbr = 'Abbr.',
  SpecificTerm = 'Specific term'
}
