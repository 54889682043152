import sc from '@mui/styled-engine';

import CircularProgress from '@mui/material/CircularProgress';

export const CircularProgressWrapperStyled = sc('div')({
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
  height: '100%',
});

export const CircularProgressStyled = sc(CircularProgress)(({
  margin: 'auto',
}));
