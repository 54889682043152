import {
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query';

import SnackBarUtils from 'common/SnackBar/SnackBarUtils';
import { User } from 'common/utils/models/user';

import * as api from './api';
import { QueryKey } from './QueryKey';
import { LanguageModel } from './models/language.model';

export function useGetLanguageList(): UseQueryResult<LanguageModel[], Error> {
  const queryClient = useQueryClient();

  return useQuery(
    QueryKey.Languages,
    api.getLanguageList,
    {
      retryOnMount: false,
      initialData: () => queryClient.getQueryData(QueryKey.Languages),
      onError: (error: Error) => {
        SnackBarUtils.toast({ id: '', message: error.message });
      },
    },
  );
}

export function useGetMyKeyCloakUserList(): UseQueryResult<User[], Error> {
  const queryClient = useQueryClient();
  const me = queryClient.getQueryData([QueryKey.AuthUser]) as User;

  return useQuery(
    QueryKey.KeyCloakUsers,
    () => api.getMyKeyCloakUserList({ myUserName: me.username }),
    {
      retryOnMount: false,
      initialData: () => queryClient.getQueryData(QueryKey.KeyCloakUsers),
      onError: (error: Error) => {
        SnackBarUtils.toast({ id: '', message: error.message });
      },
    },
  );
}
