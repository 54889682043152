import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import LogoutIcon from '@mui/icons-material/Logout';
import { Box, Stack } from '@mui/material';

import { useAuth } from 'containers/authentication/lib/auth';
import { LocationState } from 'common/utils/location-from';

import ContentToRender from './ContentToRender';
import { AppRoute } from '../routes/utils/constants';

import { AppBarStyled } from './utils/styled';

function ApplicationBar(): JSX.Element | null {
  const { logout } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  const from = (location.state as LocationState)?.from?.pathname || AppRoute.Default;
  const isDefaultPath = location.pathname === AppRoute.Default;

  const onLogoutHandle = (): void => {
    logout();
    navigate(from, { replace: true });
  };

  const justifyContent = useMemo(
    () => (
      isDefaultPath
        ? 'flex-end'
        : 'space-between'),
    [isDefaultPath],
  );

  if (location.pathname.includes(AppRoute.Login)) return null;

  return (
    <AppBarStyled
      sx={{ boxShadow: 1 }}
      position="static"
      color="default"
    >
      <Toolbar sx={{ justifyContent }}>
        <Stack
          direction="row"
          spacing={7}
          width={
            isDefaultPath
              ? 'inherit'
              : '100%'
          }
        >
          <ContentToRender />
        </Stack>
        <Box display="block" pl={5}>
          <IconButton
            size="small"
            color="info"
            onClick={onLogoutHandle}
          >
            <LogoutIcon />
          </IconButton>
        </Box>
      </Toolbar>
    </AppBarStyled>
  );
}

export default ApplicationBar;
