/* eslint-disable max-len */
import { UseMutationResult } from 'react-query';
import { ExtractionType } from '../../utils/types';
import { CreateDataSourceModel } from './models/CreateDataSource.model';

export type MutationResult<T> = UseMutationResult<T, Error, T, unknown>;

// FIXME: Replace to upper level
export const enum RequestFormData {
  ProjectId = 'project',
  SourceName = 'source_name',
  SourceLocation = 'source_location',
  DetectedLanguage = 'detected_language',
  File = 'file',
  Type = 'type',
  Text = 'text',

}

export type DeleteMutationResult = UseMutationResult<Partial<CreateDataSourceModel>, Error, { id: string, projectId: string; }, unknown>;

export type ExtractionModel = {
  project: number; // projectId
  stSensitivity: number;
  extractTypes: ExtractionType[];
};

export type Detail = { detail: string; };
