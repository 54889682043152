import { TabRoute } from 'common/components/routes/utils/constants';
import React from 'react';

export type TabsAction = { set: `${TabRoute}`; };
export type TabsState = { tabValue: `${TabRoute}`; };
export type TabsDispatch = (action: TabsAction) => void;
export type TabsContextType = {
  state: TabsState;
  dispatch: TabsDispatch;
};

export const TabsContext = React.createContext<TabsContextType | undefined>(undefined);
