import { Grid, Typography } from '@mui/material';

import { TextManagerText } from '../utils/constants';

function TextManagerInfo(): JSX.Element {
  return (
    <Grid item container xs={8} flexDirection="column" pt={4}>
      <Typography variant="subtitle2" color="GrayText">{TextManagerText.Operation}</Typography>
      <Typography variant="subtitle2" fontStyle="italic" color="GrayText">
        {TextManagerText.Note}
      </Typography>
    </Grid>
  );
}

export default TextManagerInfo;
