import { AuthProviderConfig, initReactQueryAuth } from 'react-query-auth';
import jwtDecode from 'jwt-decode';

import { Credentials } from 'common/utils/models/login';
import { Token } from 'common/storage/types';
import { User } from 'common/utils/models/user';
import { storage } from 'common/storage';
import SnackBarUtils from 'common/SnackBar/SnackBarUtils';

import {
  getUser, login, logout, relogin,
} from './api';
import { INCORRECT_LOGIN_MESSAGE } from './utils/constants';

async function loginFn(data: Credentials): Promise<User> {
  let user: User | null = null;

  try {
    const token = await login(data);
    const decodedToken = jwtDecode(token) as Token;
    storage.setToken(decodedToken);
    if (storage.getAccessToken()) {
      user = await getUser();
    }
    return user as User;
  } catch (error) {
    SnackBarUtils.toast({ actionType: 'delete', message: INCORRECT_LOGIN_MESSAGE });
    return Promise.reject(error);
  }
}

async function logoutFn(): Promise<void> {
  try {
    await logout();
  } catch (error) {
    Promise.reject(error);
  }
}

async function loadUser(): Promise<User> {
  let user = null;

  try {
    const accessToken = storage.getAccessToken();
    if (accessToken) {
      user = await getUser();
    }

    return user as User;
  } catch (error) {
    await relogin();
    return Promise.reject(error);
  }
}

const authConfig: AuthProviderConfig<User, unknown> = {
  loadUser,
  loginFn,
  registerFn: async (user: User) => user,
  logoutFn,
};

const { AuthProvider, useAuth } = initReactQueryAuth<User>(authConfig);

export { AuthProvider, useAuth };
