import { AxiosError, AxiosResponse } from 'axios';

import axiosInstance from 'common/interceptors/axios';

import Endpoint from './Endpoint';

const axios = axiosInstance;

export const resolveAttentionRequired = async (
  dataSourceIds: number[],
  parentIds: number[],
): Promise<{ detail: string; }> => new Promise<{ detail: string; }>(
  (resolve, reject) => {
    axios.post<{ detail: string; }>(Endpoint.PostResolveAttentionRequired, { dataSourceIds, parentIds })
      .then((response: AxiosResponse<{ detail: string; }>) => resolve(response.data))
      .catch((error: AxiosError<string>) => reject(error));
  },
);
