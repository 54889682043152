export type Token = {
  accessToken: string;
  expiresIn: number;
  refreshToken: string;
  refreshExpiresIn: number;
};

export enum TokenEnum {
  accessToken = '__access-token__',
  expiresIn = '__refresh-token__',
  refreshToken = '__access-expires-in__',
  refreshExpiresIn = '__refresh-expires-in__',
  navId = 'navId',
}
