import { AxiosError, AxiosResponse } from 'axios';

import axiosInstance from 'common/interceptors/axios';

import Endpoint from './Endpoint';
import { GetReportParamsModel, ReportResult } from './types';

const axios = axiosInstance;

export const getReport = async ({
  limit,
  project,
  types,
}: GetReportParamsModel): Promise<ReportResult> => new Promise<ReportResult>(
  (resolve, reject) => {
    axios.get<ReportResult>(
      `${Endpoint.CreateDataSources}?project=${project}&limit=${limit}&types=${(types).join(',')}`,
    )
      .then((response: AxiosResponse<ReportResult>) => resolve(response.data))
      .catch((error: AxiosError<string>) => reject(error));
  },
);
