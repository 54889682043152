import sc from '@mui/styled-engine';

import { Card } from '@mui/material';

export const ModalContentCardStyled = sc(Card)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  backgroundColor: 'white',
});
