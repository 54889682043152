import { Token, TokenEnum } from './types';

export const storage = {
  getAccessToken: (): string | undefined => {
    const token = localStorage.getItem(TokenEnum.accessToken);
    if (token) return token;
    return undefined;
  },
  getRefreshToken: (): string | undefined => {
    const token = localStorage.getItem(TokenEnum.refreshToken);
    if (token) return token;
    return undefined;
  },
  getRefreshExpiresIn: (): number | undefined => {
    const unix = localStorage.getItem(TokenEnum.refreshExpiresIn);
    if (unix) return +unix;
    return undefined;
  },
  getAccessExpiresIn: (): number | undefined => {
    const unix = localStorage.getItem(TokenEnum.expiresIn);
    if (unix) return +unix;
    return undefined;
  },
  setToken: (tokenObj: Token): void => {
    localStorage.setItem(TokenEnum.accessToken, tokenObj.accessToken);
    localStorage.setItem(TokenEnum.refreshToken, tokenObj.refreshToken);
    localStorage.setItem(TokenEnum.expiresIn, tokenObj.expiresIn.toString());
    localStorage.setItem(TokenEnum.refreshExpiresIn, tokenObj.refreshExpiresIn.toString());
  },

  removeNavId: () => localStorage.removeItem(TokenEnum.navId),
  clearToken: (): void => localStorage.removeItem(TokenEnum.expiresIn),
  clearAll: (): void => {
    Object.values(TokenEnum)
      .forEach((value) => {
        if (value !== TokenEnum.navId) {
          localStorage.removeItem(value);
        }
      });
  },
};
