import useGetProjectById from 'common/query-hooks/use-get-project-by-id';
import { useDeleteFile as useDeleteFileQueryHook } from '../../lib/query-hooks';

interface DeleteFile {
  onDeleteFileHandle: (id: string) => (event: React.MouseEvent<HTMLButtonElement>) => Promise<void>;
  isDeleting: boolean;
}

const useDeleteFile = (): DeleteFile => {
  const { projectId } = useGetProjectById();

  const { mutateAsync: daleteAsync, isLoading: isDeleting } = useDeleteFileQueryHook();

  const onDeleteFileHandle = (id: string) => async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    if (projectId) {
      await daleteAsync({ id, projectId: projectId.toString() });
    }
  };

  return {
    onDeleteFileHandle,
    isDeleting,
  };
};

export default useDeleteFile;
