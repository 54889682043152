/* eslint-disable camelcase */
import { useCallback } from 'react';
import { Control, useForm, UseFormHandleSubmit } from 'react-hook-form';

import useGetProjectById from 'common/query-hooks/use-get-project-by-id';
import { DataSource } from 'common/utils/models/data-source';

import { useAddUrlsSource } from '../../lib/query-hooks';
import { UrlsFormValues } from './types';

interface UploadUrl {
  control: Control<UrlsFormValues, object>;
  handleSubmit: UseFormHandleSubmit<UrlsFormValues>;
  dataSources: DataSource[] | undefined;
  onSend: ({ source_name, source_location }: UrlsFormValues) => Promise<void>;
}

export const useUploadUrl = (): UploadUrl => {
  const { control, handleSubmit, reset } = useForm<UrlsFormValues>({ mode: 'onChange' });
  const { mutateAsync: addAsync } = useAddUrlsSource();
  const { data, projectId } = useGetProjectById();

  const dataSources = data?.dataSources;

  const onSend = useCallback(async ({
    source_name,
    source_location,
  }: UrlsFormValues): Promise<void> => {
    const response = await addAsync({
      id: projectId,
      type: 'url',
      sourceName: source_name || source_location,
      sourceLocation: source_location,
    });

    if (response) reset();
  }, [addAsync, projectId, reset]);

  return {
    control,
    handleSubmit,
    dataSources,
    onSend,
  };
};
