import { NO_DATA } from './constants';

function withAlternativePhrase<T = never[] | string>(dataItem: unknown): T | JSX.Element {
  if ((Array.isArray(dataItem) && dataItem.length <= 0)) {
    return <em>{NO_DATA}</em>;
  } if (dataItem && (Array.isArray(dataItem) && dataItem.length > 0)) return dataItem as never;

  if (typeof dataItem === 'undefined' || !dataItem) return <em>{NO_DATA}</em>;
  return dataItem as T;
}

export default withAlternativePhrase;
