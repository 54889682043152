import sc from '@mui/styled-engine';

import { Card, List, ListSubheader } from '@mui/material';

export const ModalContentCardStyled = sc(Card)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  backgroundColor: 'white',
});

export const ListStyled = sc(List)(({ theme }) => ({
  width: '100%',
  maxWidth: 800,
  minWidth: 600,
  border: `1px solid  ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  padding: 0,
  maxHeight: '360px',
  overflow: 'auto',
  overflowX: 'hidden',
  paddingTop: 45,
  position: 'inherit',
}));

export const ListSubheaderStyled = sc(ListSubheader)({
  display: 'inline-flex',
  alignItems: 'center',
  position: 'absolute',
  width: 'calc(100% - 48px)',
  top: '20.8%',
  height: 45,
});
