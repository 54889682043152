export enum AppRoute {
  Login = '/login',
  Logout = '/logout',

  Default = '/projects',
  CreateProject = '/projects/create',
  Project = '/projects/:id',

  NotFound = '404',
}

export enum TabRoute {
  Description = 'description',
  EditDescription = 'description/edit',
  TextManager = 'input-manager',
  Results = 'results',
  Edit = 'edit',
}

export type Routes = AppRoute & TabRoute;
