import { useEffect, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';

import { useSetCreateOrUpdateProjectContext } from 'containers/create-project-page/local-state/hooks';
import { TabRoute } from 'common/components/routes/utils/constants';
import CreateEditInformationPage from 'common/components/create-edit-page';
import useGetProjectById from 'common/query-hooks/use-get-project-by-id';

import useBlockTab from './components/text-manager-tab/utils/useLockExtraction';
import InformationTab from './components/information-tab/InformationTab';
import TextManagerTab from './components/text-manager-tab';
import ResultsTab from './components/results-tab';
import InformationEditButton from './components/additional-components/InformationEdit';
import { TabText } from './utils/constants';
import { getTabValueFromRoute } from './utils/helpers';
import { useSetTabOrientationContext, useTabsContext } from './local-state/hooks';

import {
  CircularSpinnerStyled,
  ProjectManagerContainerStyled,
  TabPanelsWrapper,
  TabsContainerStyled, TabStyled,
} from './utils/styled';

function ProjectManagerPage(): JSX.Element {
  const { dispatch: createDispatch } = useSetCreateOrUpdateProjectContext();
  const { dispatch: tabOrientationDispatch } = useSetTabOrientationContext();
  const { dispatch, state: { tabValue } } = useTabsContext();

  const location = useLocation();
  const { data: project } = useGetProjectById();
  const {
    blockIfExtracting,
    blockIfNoDataSources,
    blockIfDone,
  } = useBlockTab();

  const isEditInformation = location.pathname.includes(TabRoute.EditDescription);

  const handleChange = (_: React.SyntheticEvent, newValue: `${TabRoute}`): void => {
    dispatch({ set: newValue });
  };

  const tabContentWidthInXs = useMemo(() => {
    if (isEditInformation) {
      return 12;
    } if (tabValue !== TabRoute.Description) return 11;
    return 6;
  }, [isEditInformation, tabValue]);

  useEffect(() => {
    dispatch({ set: getTabValueFromRoute(location.pathname) || TabRoute.TextManager });
  }, [dispatch, location.pathname]);

  useEffect(() => {
    createDispatch({ type: 'edit' });
  }, [createDispatch]);

  useEffect(() => {
    if (tabValue === 'input-manager') {
      tabOrientationDispatch({ setOrientationTo: 'project-manager' });
    } else if (tabValue === 'results') {
      tabOrientationDispatch({ setOrientationTo: 'results' });
    }
  }, [tabOrientationDispatch, tabValue]);

  return (
    <ProjectManagerContainerStyled item xs={12}>
      <TabContext value={tabValue}>
        <TabsContainerStyled item container xs={12}>
          <Grid
            item
            xs={11}
            sx={{ borderBottom: 1, borderColor: 'divider' }}
          >
            <TabList onChange={handleChange} sx={{ maxHeight: 65 }}>
              <TabStyled
                sx={{ typography: 'subtitle1' }}
                component={Link}
                label={TabText.Description}
                value={TabRoute.Description}
                to={TabRoute.Description}
              />
              <TabStyled
                sx={{ typography: 'subtitle1' }}
                disabled={blockIfExtracting}
                component={Link}
                label={TabText.TextManager}
                value={TabRoute.TextManager}
                to={TabRoute.TextManager}
              />
              <TabStyled
                sx={{ typography: 'subtitle1' }}
                disabled={blockIfNoDataSources || (!blockIfDone && !blockIfExtracting)}
                component={Link}
                label={TabText.Results}
                value={TabRoute.Results}
                to={TabRoute.Results}
                icon={project.status === 'Extracting' ? (
                  <CircularSpinnerStyled size="small" color="primary" />
                ) : ''}
                iconPosition="end"
              />
            </TabList>
          </Grid>
          <TabPanelsWrapper
            item
            xs={tabContentWidthInXs}
            $isOverflowHidden={tabValue === TabRoute.TextManager}
          >
            {
              isEditInformation
                ? <CreateEditInformationPage />
                : <InformationTab />
            }
            <TextManagerTab />
            { (!isEditInformation && tabValue === TabRoute.Description) && <InformationEditButton /> }
            <ResultsTab />
          </TabPanelsWrapper>
        </TabsContainerStyled>
      </TabContext>
    </ProjectManagerContainerStyled>
  );
}

export default ProjectManagerPage;
