import sc from '@mui/styled-engine';

import { Avatar, Grid } from '@mui/material';

export const AvatarStyled = sc(Avatar)({
  width: 48,
  height: 48,
});

export const IvitedPeopleItemStyled = sc(Grid)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(2, 0),
  alignItems: 'flex-end',
}));

export const IvitedPeopleManipulationStyled = sc(Grid)({
  justifyContent: 'space-between',
  display: 'flex',
});
