export const enum InputLabelText {
  Name = 'Name',
  Language = 'Language',
  Description = 'Description (optional)',
  StartDate = 'Event start date',
  EndDate = 'Event end date',
  Shared = 'Shared with',
}

export const enum TextManagerText {
  Operation = 'Please add texts, files or URL(s) for extraction.',
  Note = 'NOTE: Text editing will invalidate the results of the previous extraction.',
  FileUploadDescription = 'You can upload a file in one of the following formats: PDF, TXT',
  Files = 'Files:',
  URLs = 'URLs:',
  Texts = 'Texts:',
}

export const enum InputPlaceholderText {
  URLName = 'URL name (optional)',
  URL = 'URL',
  TextName = 'Text name (optional)',
  Typing = 'Start typing...',
}
