import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import Login from 'containers/authentication';
import CreateEditProjectPage from 'containers/create-project-page';
import ProjectManagerPage from 'containers/project-manager-page';
import NotFound from 'common/components/not-found';

import InformationTab from 'containers/project-manager-page/components/information-tab/InformationTab';
import TextManagerTab from 'containers/project-manager-page/components/text-manager-tab';
import ResultsTab from 'containers/project-manager-page/components/results-tab';
import CreateEditInformationPage from 'common/components/create-edit-page';
import { AppRoute, TabRoute } from './constants';
import withPrivate from './with-private-route-HOC';
import withSuspense from './with-suspense-HOC';

const ProjectListPage = lazy(() => import('containers/project-list-page'));

export const routes: RouteObject[] = [
  {
    path: AppRoute.Login,
    element: <Login />,
  },
  {
    path: AppRoute.Default,
    element: withSuspense(withPrivate(<ProjectListPage />)),
  },
  {
    path: AppRoute.CreateProject,
    element: withPrivate(<CreateEditProjectPage />),
  },
  {
    path: AppRoute.Project,
    element: withSuspense(withPrivate(<ProjectManagerPage />)),
    children: [
      {
        path: TabRoute.Description,
        element: InformationTab,
      },
      {
        path: TabRoute.EditDescription,
        element: withPrivate(<CreateEditInformationPage />),
      },
      {
        path: TabRoute.TextManager,
        element: TextManagerTab,
      },
      {
        path: TabRoute.Results,
        element: ResultsTab,
      },
    ],
  },
  {
    path: '*',
    element: <NotFound />,
  },
];
