import { useNavigate } from 'react-router-dom';

import { Button, Typography } from '@mui/material';

import { AppRoute } from '../routes/utils/constants';

function NotFound(): JSX.Element {
  const navigate = useNavigate();
  return (
    <>
      {/* TODO: Create the Not Found page. */}
      <Typography>Page not found! Make me nice 😕😴</Typography>
      <Button onClick={() => navigate(AppRoute.Default)}>Home</Button>
    </>
  );
}

export default NotFound;
