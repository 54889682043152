/* eslint-disable max-len */
import CreateEditProjectContextProvider from 'containers/create-project-page/local-state/CreateEditProjectContextProvider';
import SendEmailContextProvider from 'common/components/create-edit-page/local-state/SendEmailContextProvider';
import ProjectManagerContextProvider from 'containers/project-manager-page/local-state/ProjectManagerContextProvider';

function AppContextProvider({ children }: {children: React.ReactNode}): JSX.Element {
  return (
    <ProjectManagerContextProvider>
      <CreateEditProjectContextProvider>
        <SendEmailContextProvider>
          {children}
        </SendEmailContextProvider>
      </CreateEditProjectContextProvider>
    </ProjectManagerContextProvider>
  );
}

export default AppContextProvider;
