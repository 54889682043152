import CreateEditInformationPage from 'common/components/create-edit-page';
import { useEffect } from 'react';
import { useSetCreateOrUpdateProjectContext } from './local-state/hooks';

function CreateEditProjectPage(): JSX.Element {
  const { dispatch } = useSetCreateOrUpdateProjectContext();

  useEffect(() => { dispatch({ type: 'create' }); }, [dispatch]);

  return <CreateEditInformationPage />;
}

export default CreateEditProjectPage;
