import {
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query';

import SnackBarUtils from 'common/SnackBar/SnackBarUtils';
import { storage } from 'common/storage';
import { relogin } from 'containers/authentication/lib/api';

import * as api from './api';
import QueryKey from './QueryKey';

import { ProjectListResult, ProjectModel } from './models/Project.model';

export function useGetProjectList(): UseInfiniteQueryResult<ProjectListResult, Error> {
  const queryClient = useQueryClient();

  return useInfiniteQuery<ProjectListResult, Error, ProjectListResult, QueryKey.Projects>(
    QueryKey.Projects,
    ({ pageParam = 0 }: { pageParam?: number; }) => api.getProjectList(pageParam as number),
    {
      // If some project is in extraction refetches the list.
      refetchInterval: (data) => {
        data?.pages.forEach((page) => {
          const isExtractingProjectExistInList = page.results.some((res) => res.status === 'Extracting');
          const isInProgressProjectExistInList = page.results.some((res) => res.status === 'In progress');
          if (isExtractingProjectExistInList || isInProgressProjectExistInList) {
            return 5000;
          }

          return false;
        });

        return false;
      },
      getNextPageParam: (lastPage) => lastPage.next,
      meta: { limit: 50, offset: 0 },
      initialData: () => queryClient.getQueryData(QueryKey.Projects),
      onError: async () => {
        await relogin();
      },
    },
  );
}

export function useGetProjectBy(id: string | undefined): UseQueryResult<ProjectModel, Error> {
  const queryClient = useQueryClient();

  return useQuery(
    [QueryKey.Project, id],
    (input) => api.getProjectBy(input.queryKey[1] as string),
    {
      enabled: !!id && +id !== 0,
      refetchOnWindowFocus: false,
      initialData: () => queryClient.getQueryData([QueryKey.Project, id]),
      onSettled: (data) => queryClient.setQueryData(QueryKey.DataSource, data?.dataSources),
      onError: async () => {
        await relogin();
      },
    },
  );
}

export function useDeleteProject(): UseMutationResult<ProjectModel, Error, string, unknown> {
  const queryClient = useQueryClient();
  return useMutation(
    (input: string) => api.deleteProjectBy(input as string),
    {
      onSettled: async (id) => {
        storage.removeNavId();
        await queryClient.invalidateQueries(QueryKey.Projects);
        queryClient.removeQueries([QueryKey.Project, id]);
      },
      onError: (error: Error, variables) => {
        SnackBarUtils.toast({ id: variables, message: error.message });
      },
    },
  );
}
