import { memo } from 'react';
import { useNavigate } from 'react-router-dom';

import { Grid, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { AppRoute } from 'common/components/routes/utils/constants';
import { Status } from 'containers/project-list-page/utils/enums';

import StatusChip from 'containers/project-list-page/components/StatusChip';
import { TEXT } from '../utils/constants';

import { ButtonStyled } from '../utils/styled';

interface CreateProjectAppBarSetProps{
  headerText: string;
  withStatus?: boolean;
  status?: `${Status}`;
}

function CreateProjectAppBarSet(
  {
    headerText,
    withStatus,
    status,
  }: CreateProjectAppBarSetProps,
): JSX.Element {
  const navigate = useNavigate();

  const onNavigateToProjects = (): void => {
    navigate(AppRoute.Default);
  };

  return (
    <Grid item container wrap="nowrap">
      <Grid item xs={6}>
        <ButtonStyled
          variant="contained"
          onClick={onNavigateToProjects}
        >
          <ArrowBackIcon />
          &nbsp;
          {TEXT.BUTTON.TO_PROJECTS}
        </ButtonStyled>
      </Grid>
      <Grid item container xs={17} flexDirection="row" alignItems="center">
        <Typography variant="subtitle1" mr={5}>{headerText}</Typography>
        {(withStatus && status) && <StatusChip status={status} />}
      </Grid>
    </Grid>
  );
}

export default memo(CreateProjectAppBarSet);
