import sc from '@mui/styled-engine';

import { Stack } from '@mui/material';

export const LoginContainerStyled = sc(Stack)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: 360,
});
